let BrandSectorOptions = [
  {
    value: "Agriculture & horticulture",
    label: "Agriculture & horticulture",
    children: [
      {
        value: "Agricultural products",
        label: "Agricultural products",
      },
      {
        value: "Horticulture & landscaping",
        label: "Horticulture & landscaping",
      },
      {
        value: "Agriculture & horticulture",
        label: "Agriculture & horticulture",
      },
    ],
  },
  {
    value: "Animal care",
    label: "Animal care",
    children: [
      {
        value: "Animal care",
        label: "Animal care",
      },
    ],
  },
  {
    value: "Automotive",
    label: "Automotive",
    children: [
      {
        value: "Dealership",
        label: "Dealership",
      },
      {
        value: "Automotive",
        label: "Automotive",
      },
      {
        value: "Other automotive",
        label: "Other automotive",
      },
      {
        value: "Aftermarket & auto parts",
        label: "Aftermarket & auto parts",
      },
      {
        value: "Parts & aftermarket",
        label: "Parts & aftermarket",
      },
      {
        value: "Tyres",
        label: "Tyres",
      },
      {
        value: "Sharing/subscription",
        label: "Sharing/subscription",
      },
      {
        value: "Cars",
        label: "Cars",
      },
      {
        value: "Motorcycles",
        label: "Motorcycles",
      },
      {
        value: "Vehicles",
        label: "Vehicles",
      },
      {
        value: "Trucks & buses",
        label: "Trucks & buses",
      },
    ],
  },
  {
    value: "Beverages",
    label: "Beverages",
    children: [
      {
        value: "Alcoholic beverages distributor",
        label: "Alcoholic beverages distributor",
      },
      {
        value: "Beer",
        label: "Beer",
      },
      {
        value: "Cider",
        label: "Cider",
      },
      {
        value: "Spirits & liquors",
        label: "Spirits & liquors",
      },
      {
        value: "Wines & Champagnes",
        label: "Wines & Champagnes",
      },
      {
        value: "Dairy & soy drinks & milk alternatives",
        label: "Dairy & soy drinks & milk alternatives",
      },
      {
        value: "Other hot drinks",
        label: "Other hot drinks",
      },
      {
        value: "Coffee",
        label: "Coffee",
      },
      {
        value: "Hot drinks",
        label: "Hot drinks",
      },
      {
        value: "Non-alcoholic beverages",
        label: "Non-alcoholic beverages",
      },
      {
        value: "Energy & sport drinks",
        label: "Energy & sport drinks",
      },
      {
        value: "Soft drinks",
        label: "Soft drinks",
      },
      {
        value: "Water",
        label: "Water",
      },
    ],
  },
  {
    value: "Business services",
    label: "Business services",
    children: [
      {
        value: "Advertising, PR & sponsorship agencies",
        label: "Advertising, PR & sponsorship agencies",
      },
      {
        value: "Catering & hospitality",
        label: "Catering & hospitality",
      },
      {
        value: "Conferencing & exhibitions",
        label: "Conferencing & exhibitions",
      },
      {
        value: "Employment services",
        label: "Employment services",
      },
      {
        value: "Investment",
        label: "Investment",
      },
      {
        value: "Legal services",
        label: "Legal services",
      },
      {
        value: "Management consultancies",
        label: "Management consultancies",
      },
      {
        value: "Other business services",
        label: "Other business services",
      },
      {
        value: "Parking lots & garages",
        label: "Parking lots & garages",
      },
      {
        value: "Professional services",
        label: "Professional services",
      },
      {
        value: "Security systems & services",
        label: "Security systems & services",
      },
    ],
  },
  {
    value: "Clothing & accessories",
    label: "Clothing & accessories",
    children: [
      {
        value: "Athletic",
        label: "Athletic",
      },
      {
        value: "Fashion",
        label: "Fashion",
      },
      {
        value: "Footwear",
        label: "Footwear",
      },
      {
        value: "Sunglasses",
        label: "Sunglasses",
      },
      {
        value: "Jewellery",
        label: "Jewellery",
      },
      {
        value: "Watches & jewellery",
        label: "Watches & jewellery",
      },
      {
        value: "Workwear & PPE",
        label: "Workwear & PPE",
      },
    ],
  },
  {
    value: "Consumer goods & services",
    label: "Consumer goods & services",
    children: [
      {
        value: "Consumer electronics",
        label: "Consumer electronics",
      },
      {
        value: "Glassware & trophies",
        label: "Glassware & trophies",
      },
      {
        value: "Home products & services",
        label: "Home products & services",
      },
      {
        value: "Luggage",
        label: "Luggage",
      },
      {
        value: "Consumer goods & services",
        label: "Consumer goods & services",
      },
      {
        value: "Office products & services",
        label: "Office products & services",
      },
      {
        value: "Personal care",
        label: "Personal care",
      },
      {
        value: "Photographic products & services",
        label: "Photographic products & services",
      },
      {
        value: "Tobacco & electronic cigarettes",
        label: "Tobacco & electronic cigarettes",
      },
      {
        value: "Toys & games",
        label: "Toys & games",
      },
    ],
  },
  {
    value: "Education",
    label: "Education",
    children: [
      {
        value: "Language Instruction",
        label: "Language Instruction",
      },
      {
        value: "Education",
        label: "Education",
      },
      {
        value: "Universities, colleges & schools",
        label: "Universities, colleges & schools",
      },
    ],
  },
  {
    value: "Energy & utilities",
    label: "Energy & utilities",
    children: [
      {
        value: "Energy & utilities",
        label: "Energy & utilities",
      },
      {
        value: "Fuel",
        label: "Fuel",
      },
      {
        value: "Oil & petrol",
        label: "Oil & petrol",
      },
      {
        value: "Renewable energy",
        label: "Renewable energy",
      },
    ],
  },
  {
    value: "Entertainment & leisure",
    label: "Entertainment & leisure",
    children: [
      {
        value: "Film, music & theatre",
        label: "Film, music & theatre",
      },
      {
        value: "Fitness clubs & gyms",
        label: "Fitness clubs & gyms",
      },
      {
        value: "Entertainment & leisure",
        label: "Entertainment & leisure",
      },
      {
        value: "Sports venues & facilities",
        label: "Sports venues & facilities",
      },
    ],
  },
  {
    value: "Financial services",
    label: "Financial services",
    children: [
      {
        value: "Banking & Financial Services",
        label: "Banking & Financial Services",
      },
      {
        value: "Brokerage & consulting",
        label: "Brokerage & consulting",
      },
      {
        value: "Credit cards",
        label: "Credit cards",
      },
      {
        value: "Cryptocurrency",
        label: "Cryptocurrency",
      },
      {
        value: "Insurance & pensions",
        label: "Insurance & pensions",
      },
    ],
  },
  {
    value: "Food",
    label: "Food",
    children: [
      {
        value: "Breakfast cereals",
        label: "Breakfast cereals",
      },
      {
        value: "Confectionery",
        label: "Confectionery",
      },
      {
        value: "Dairy",
        label: "Dairy",
      },
      {
        value: "Health supplements",
        label: "Health supplements",
      },
      {
        value: "Other food",
        label: "Other food",
      },
      {
        value: "Snacks",
        label: "Snacks",
      },
      {
        value: "Supplements & sports nutrition",
        label: "Supplements & sports nutrition",
      },
    ],
  },
  {
    value: "Gambling",
    label: "Gambling",
    children: [
      {
        value: "Betting & gambling",
        label: "Betting & gambling",
      },
      {
        value: "Casino",
        label: "Casino",
      },
      {
        value: "Lotteries",
        label: "Lotteries",
      },
    ],
  },
  {
    value: "Government",
    label: "Government",
    children: [
      {
        value: "Cities & regions",
        label: "Cities & regions",
      },
      {
        value: "Government agencies",
        label: "Government agencies",
      },
      {
        value: "Sports councils",
        label: "Sports councils",
      },
      {
        value: "Sports ministries",
        label: "Sports ministries",
      },
    ],
  },
  {
    value: "Industrial goods & services",
    label: "Industrial goods & services",
    children: [
      {
        value: "Aerospace",
        label: "Aerospace",
      },
      {
        value: "Building materials",
        label: "Building materials",
      },
      {
        value: "Chemicals",
        label: "Chemicals",
      },
      {
        value: "Construction",
        label: "Construction",
      },
      {
        value: "Engineering",
        label: "Engineering",
      },
      {
        value: "Heavy industry",
        label: "Heavy industry",
      },
      {
        value: "Removal Company",
        label: "Removal Company",
      },
      {
        value: "Waste management",
        label: "Waste management",
      },
    ],
  },
  {
    value: "Logistics & delivery",
    label: "Logistics & delivery",
    children: [
      {
        value: "Delivery & shipping",
        label: "Delivery & shipping",
      },
      {
        value: "Logistics & delivery",
        label: "Logistics & delivery",
      },
    ],
  },
  {
    value: "Media",
    label: "Media",
    children: [
      {
        value: "Broadcasting",
        label: "Broadcasting",
      },
      {
        value: "Media group",
        label: "Media group",
      },
      {
        value: "Newspaper & magazine",
        label: "Newspaper & magazine",
      },
      {
        value: "Other media",
        label: "Other media",
      },
      {
        value: "Production & distribution",
        label: "Production & distribution",
      },
      {
        value: "Website",
        label: "Website",
      },
    ],
  },
  {
    value: "Organisations",
    label: "Organisations",
    children: [
      {
        value: "Sports organisation",
        label: "Sports organisation",
      },
    ],
  },
  {
    value: "Pharmaceuticals & healthcare",
    label: "Pharmaceuticals & healthcare",
    children: [
      {
        value: "Hospitals",
        label: "Hospitals",
      },
      {
        value: "Pharmaceuticals & healthcare",
        label: "Pharmaceuticals & healthcare",
      },
      {
        value: "Sport medical centres",
        label: "Sport medical centres",
      },
    ],
  },
  {
    value: "Real estate",
    label: "Real estate",
    children: [
      {
        value: "Real estate",
        label: "Real estate",
      },
      {
        value: "Real estate agents / brokers",
        label: "Real estate agents / brokers",
      },
    ],
  },
  {
    value: "Restaurant",
    label: "Restaurant",
    children: [
      {
        value: "Cafe",
        label: "Cafe",
      },
      {
        value: "Quick service restaurant &amp; fast food",
        label: "Quick service restaurant &amp; fast food",
      },
    ],
  },
  {
    value: "Retail",
    label: "Retail",
    children: [
      {
        value: "Automotive parts (retail)",
        label: "Automotive parts (retail)",
      },
      {
        value: "Clothing (retail)",
        label: "Clothing (retail)",
      },
      {
        value: "Department store",
        label: "Department store",
      },
      {
        value: "Electronics (retail)",
        label: "Electronics (retail)",
      },
      {
        value: "Online (retail)",
        label: "Online (retail)",
      },
      {
        value: "Opticians",
        label: "Opticians",
      },
      {
        value: "Supermarket",
        label: "Supermarket",
      },
    ],
  },
  {
    value: "Sports equipment",
    label: "Sports equipment",
    children: [
      {
        value: "Sports equipment",
        label: "Sports equipment",
      },
    ],
  },
  {
    value: "Technology",
    label: "Technology",
    children: [
      {
        value: "Computers & software",
        label: "Computers & software",
      },
      {
        value: "Internet & IT services",
        label: "Internet & IT services",
      },
      {
        value: "Consumer applications",
        label: "Consumer applications",
      },
    ],
  },
  {
    value: "Telecommunications",
    label: "Telecommunications",
    children: [
      {
        value: "Mobile phone operators",
        label: "Mobile phone operators",
      },
      {
        value: "Telecommunications operators",
        label: "Telecommunications operators",
      },
    ],
  },
  {
    value: "Other",
    label: "Other",
  },
  {
    value: "Travel & tourism",
    label: "Travel & tourism",
    children: [
      {
        value: "Cruises",
        label: "Cruises",
      },
      {
        value: "Hotels & resorts",
        label: "Hotels & resorts",
      },
      {
        value: "Tourist boards",
        label: "Tourist boards",
      },
      {
        value: "Travel agencies & operators",
        label: "Travel agencies & operators",
      },
      {
        value: "Airlines",
        label: "Airlines",
      },
      {
        value: "Other passenger transportation",
        label: "Other passenger transportation",
      },
    ],
  },
];

export default BrandSectorOptions;
