import { ThemeConfig } from "antd";

export const cdbTheme: ThemeConfig = {
  token: {
    colorPrimary: "#c800c0",
    colorLink: "#c800c0", // link color
    colorSuccess: "#52c41a", // success state color
    colorWarning: "#faad14", // warning state color
    colorError: "#f5222d", // error state color
    fontSize: 14, // major text font size
    colorTextHeading: "rgba(0, 0, 0, 0.85)", // heading text color
    colorText: "rgba(0, 0, 0, 0.65)", // major text color
    colorTextSecondary: "rgba(0, 0, 0, 0.45)", // secondary text color
    colorTextDisabled: "rgba(0, 0, 0, 0.25)", // disable state color
    borderRadius: 4, // major border radius
    colorBorder: "#d9d9d9", // major border color
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)", // major shadow for layers
  }
};
