import { CSSProperties } from 'react';
import {
  AttachmentType,
  PlayerTransactionType,
  TermStatus,
  TermType,
  UserRole,
} from './enums';
import { UploadFileStatus } from 'antd/es/upload/interface';

export enum CompanyType {
  default = 'default',
  club = 'club',
  external = 'external',
}

export type Company = {
  id?: string;
  name?: string;
  logo?: string;

  display?: string;
  key?: number;
  text?: string;
  value?: string;
  companyType: CompanyType;
  clubInPremierLeague: boolean;

  plSeasons: PLSeason[];

  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
};

export type PLSeason = {
  id: string;
  label: string;
  start_date: Date;
  end_date: Date;
};

export type User = {
  id: string;
  name: string;
  plasId: string;
  plasactive: boolean;
  role: UserRole;
  company: Company;
  companyLogo: string;
  email: string;
  exp: number;
  iat: number;
  termsShared: Term[];

  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
};

export type Auth = {
  isAuthenticated: boolean;
  user: User;
};

export type JournalComment = {
  comment: string;
  date: string;
  term: Term;
  user: User;
};

export type AttachmentFile = {
  uid: string;
  id: string;
  name: string;
  status?: UploadFileStatus;
  response?: string;
  url?: string;
  type?: AttachmentType;
};

export type BespokeAttachments = {
  unredacted_agreement: AttachmentFile[];
  redacted_agreement: AttachmentFile[];
  pl_reports: AttachmentFile[];
  apt_declarations: AttachmentFile[];
};
export type AttachmentFileRaw = AttachmentFile & {
  id: string;
  fileName: string;
};

export type Audit = {
  id: string;
  userId: string;
  user: User;
  userEmail: string;
  createdAt: string;
  label: string;
  type: string;
  description: string;
};

export type Term = {
  id: string;
  key: number;
  number: number;
  title: string;
  type: TermType;
  description: string;
  company: Company;
  state: TermStatus;
  contractingPartyJurisdiction: [];
  brandSector: [];
  territories: [];
  teamRights: string;
  createdBy: string;
  __v: number;
  subjectMatter?: string;
  contractingParty?: string;
  feesInKind?: string;
  feesCash?: string;
  feesTotal?: string;
  agreementDate?: string;
  commencementDate?: string;
  expiryDate: string;
  resolvedByName?: string;
  resolvedByTitle?: string;
  resolutionDate?: string;
  transactionResolutionDate?: string;
  submissionDate?: string;
  convertedOn?: string;
  submittedToPlDate?: string;
  parentContractingParty?: string;
  brand?: string;
  associatedParty?: string;
  armsLength?: string;
  summary?: string;
  term?: string;
  renewalProvisions?: string;
  feesSummary?: string;
  feesRoyalties?: string;
  commercialIncentives?: string;
  paymentSchedule?: string;
  associatedFees?: string;
  partnerDesignations?: string;
  exclusiveCategories?: string;
  nonExclusiveCategories?: string;
  assetsProvided?: string;
  prospectingProcess?: string;
  agencyProcess?: string;
  servicesProvided?: string;
  partnerRights?: string;
  licensingRights?: string;
  otherRights?: string;
  competitiveProcess?: string;
  competingBidders?: string;
  competingBids?: string;
  transactionNature?: string;
  comparableDealsValue?: string;
  sourceOfRelationship?: string;
  complianceChecks?: string;
  fmvAssessment?: string;
  aptDeclarationToFmv?: string;
  natureOfTransaction?: string;
  competingOffers?: string;
  associatedPartyFmv?: string;
  valueOfComparableDeals?: string;
  sharedWith: string[];
  createdAt?: string;
  furtherDetailsRequested?: boolean;
  timeTakenForResponse?: number;
  // threshold
  strategicRationale?: string;
  // assoc party
  strategicRationaleClub?: string;
  strategicRationaleContractingParty?: string;
};

export type PlayerTransaction = {
  id: string;
  number: number;
  company: Company;
  type: PlayerTransactionType;
  fees?: string;
  playerId: string;
  playerName: string;
  agreementDate?: string;
  submissionDate?: string;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: string;
  updatedBy?: string;
};

export type TermForm = {
  state: TermStatus;
  number: string;
  // tab 1
  company: string;
  title: string;
  description: string;
  agreementDate: string;
  contractingParty: string;
  contractingPartyJurisdiction: string[];
  parentContractingParty: string;
  brand: string;
  brandSector: string;
  subjectMatter: string;
  associatedParty: string;
  armsLength: string;
  // tab 2
  summary: string;
  commencementDate: string;
  expiryDate: string;
  term: string;
  renewalProvisions: string;
  // threshold
  strategicRationale?: string;
  //assoc party
  strategicRationaleClub?: string;
  strategicRationaleContractingParty?: string;
  // tab 3
  fees: TermFormField[];
  feesSummary: string;
  feesTotal: string;
  feesCash: string;
  feesInKind: string;
  feesRoyalties: string;
  commercialIncentives: string;
  associatedFees: string;
  // tab 4
  partnerDesignations: string;
  exclusiveCategories: string;
  nonExclusiveCategories: string;
  territories: string[];
  teamRights: string;
  assetsProvided: string;
  servicesProvided: string;
  partnerRights: string;
  licensingRights: string;
  otherRights: string;
  // tab 5
  competitiveProcess: string;
  competingBidders: string;
  competingBids: string;
  transactionNature: string;
  comparableDealsValue: string;
  //tab 6
  sourceOfRelationship: string;
  complianceChecks: string;
  fmvAssessment: string;
  prospectingProcess: string;
  agencyProcess: string;
  competingOffers: string;
  associatedPartyFmv: string;
  aptDeclarationToFmv: string;
  // tab 9
  furtherDetailsRequested: boolean;
  timeTakenForResponse: string;
  furtherComments: string;
  provisionalDeterminationDate: string;
  finalDeterminationDate: string;
  boardApprovalDate: string;
  transactionResolutionDate: string;
};

export type PlayerTransactionForm = {
  number: string;
  // tab 1
  company: string;
  playerId: number;
  playerName: string;
  agreementDate: string;
  submissionDate: string;
  fees: PlayerTransactionFormField[];
};

export type TermFormField = {
  name: keyof TermForm;
  type:
    | 'select'
    | 'daterange'
    | 'text'
    | 'cascader'
    | 'textarea'
    | 'date'
    | 'radio'
    | 'format_row'
    | 'number';
  label?: string;
  defaultValue?: any;
  disabled?: boolean;
  onChange?: (v: any) => void;
  placeholder?: string;
  number?: { formatter: (value: any) => string; parser: (value: any) => any };
  tooltip?: string;
  rules?: any[];
  select?: {
    options: any;
    mode?: 'multiple' | 'tags';
    filterOptionsBy?: string;
  };
  dontDisplay?: boolean;
  maxLength?: number;
  cascader?: { options: any; mode: string };
  style?: CSSProperties;
  options?: { value: string | boolean; label: string }[];
  entities?: TermFormField[];
};

export type PlayerTransactionFormField = {
  name: keyof PlayerTransactionForm;
  type:
    | 'select'
    | 'daterange'
    | 'text'
    | 'cascader'
    | 'textarea'
    | 'date'
    | 'radio'
    | 'format_row'
    | 'number';
  label?: string;
  placeholder?: string;
  defaultValue?: any;
  disabled?: boolean;
  onChange?: (v: any) => void;
  number?: { formatter: (value: any) => string; parser: (value: any) => any };
  tooltip?: string;
  rules?: any[];
  select?: {
    options: any;
    mode?: 'multiple' | 'tags';
    filterOptionsBy?: string;
  };
  dontDisplay?: boolean;
  maxLength?: number;
  cascader?: { options: any; mode: string };
  style?: CSSProperties;
  options?: { value: string; label: string }[];
  entities?: PlayerTransactionFormField[];
};

// FILTER FORM
export type TermListFilter = {
  title?: string;
  text?: string;
  number?: string;
  state?: TermStatus[] | undefined;
  type?: TermType[] | undefined;
  submissionDate?: string;
  submittedToPlDate?: string;
  agreementDate?: string;
  commencementDate?: string;
  brand?: string;
  brandSector?: string[];
  subjectMatter?: string[];
  territories?: string[];
  associatedParty?: string;
  armsLength?: string;
};

// FILTER FORM
export type PlayerTransactionListFilter = {
  number?: string;
  type?: TermType[] | undefined;
  submissionDate?: string;
  agreementDate?: string;
  playerName?: string;
  playerId?: string;
};

export type UserListFilter = {
  email?: string;
  companyId?: string[];
};

export enum UserListFilterEnum {
  email = 'email',
  companyId = 'companyId',
}

export enum PlayerTransactionListFilterEnum {
  number = 'number',
  type = 'type',
  submissionDate = 'submissionDate',
  agreementDate = 'agreementDate',
  playerName = 'playerName',
  playerId = 'playerId',
  companyId = 'companyId',
}

export enum TermListFilterEnum {
  text = 'text',
  title = 'title',
  number = 'number',
  state = 'state',
  type = 'type',
  submissionDate = 'submissionDate',
  resolutionDate = 'resolutionDate',
  submittedToPlDate = 'submittedToPlDate',
  agreementDate = 'agreementDate',
  commencementDate = 'commencementDate',
  brand = 'brand',
  brandSector = 'brandSector',
  subjectMatter = 'subjectMatter',
  territories = 'territories',
  associatedParty = 'associatedParty',
  armsLength = 'armsLength',
  description = 'description',
  contractingParty = 'contractingParty',
  summary = 'summary',
  competingBidders = 'competingBidders',
  partnerDesignations = 'partnerDesignations',
  exclusiveCategories = 'exclusiveCategories',
  nonExclusiveCategories = 'nonExclusiveCategories',
}

export enum AdminTermListFilterEnum {
  companyId = 'companyId',
}

export const FullTermListFilterEnum = Object.assign(
  {},
  TermListFilterEnum,
  AdminTermListFilterEnum,
);

export const dateFilters = [
  FullTermListFilterEnum.submissionDate,
  FullTermListFilterEnum.agreementDate,
  FullTermListFilterEnum.commencementDate,
  FullTermListFilterEnum.submittedToPlDate,
];

export type AdminTermListFilter = {
  companyId?: string[];
};

export type FullTermListFilterForm = TermListFilter & AdminTermListFilter;
export type AuditFilterForm = AuditFilter;
export type UserListFilterForm = UserListFilter;
export type PlayerListFilterForm = PlayerTransactionListFilter;

export type FilterField = {
  name:
    | keyof FullTermListFilterForm
    | keyof AuditFilterForm
    | keyof UserListFilterForm
    | keyof PlayerListFilterForm;
  label: string;
  type:
    | 'select'
    | 'daterange'
    | 'text'
    | 'number'
    | 'cascader'
    | 'textarea'
    | 'date'
    | 'radio';
  tooltip?: string;
  defaultValue?: any;
  disabled?: boolean;
  onChange?: (v: any) => void;
  placeholder?: string;
  number?: {
    formatter: (value: any) => string;
    parser: (value: string) => string;
  };
  rules?: any[];
  select?: {
    options: any;
    mode?: 'multiple' | 'tags';
    filterOptionsBy?: string;
  };
  dontDisplay?: boolean;
  maxLength?: number;
  cascader?: { options: any; mode: string };
  style?: CSSProperties;
  options?: { value: string; label: string }[];
  entities?: {
    name: string;
    label: string;
    rules: any[];
    type: string;
    number?: { formatter: (value: any) => string; parser: (value: any) => any };
  }[];
};

export enum AuditFilterEnum {
  type = 'type',
}

export enum AuditType {
  all = 'all',
  views = 'views',
  changes = 'changes',
}

export type AuditFilter = {
  // user: string;
  type?: AuditType;
};

export type TransactionsPerCLubFilter = {
  companyId?: string;
  transactionType?: TermType & 'all';
  furtherDetails?: boolean | 'all';
  transactionStatus?: TermStatus & 'all';
  submittedToPlDate?: string[];
};

export type CreateDraftTermDto = {
  company: string;
  title: string;
  description: string;
  type: TermType;
};

export type CreateDraftPlayerTransactionDto = {
  company: string;
  playerId: string;
  playerName: string;
  type: TermType;
  fees: number;
};

export type CreateCommentDto = {
  comment: string;
  userId: string;
  termId: string;
};

export type CreateUserDto = {
  name: string;
  email: string;
  role: string;

  newUser: Partial<User>;
};
