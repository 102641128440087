import { Select } from "antd";
import React from "react";

const { Option } = Select;

let genderRightsOptions = ["Men's team only", "Women's team only", "Both"];

const getTeamRightsOptions = () =>
  genderRightsOptions.map((genderOption) => (
    <Option key={genderOption} value={genderOption}>
      {genderOption}
    </Option>
  ));
export default getTeamRightsOptions;
