import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { message } from 'antd';
import { Modal } from 'antd';

interface IHttpOpts {
  promptError?: boolean;
  config?: AxiosRequestConfig;
}

export namespace HttpController {
  export async function get<T>(url: string, opts?: IHttpOpts): Promise<T> {
    return await request<T>('get', { url, opts });
  }

  export async function del<T>(url: string, opts?: IHttpOpts): Promise<T> {
    return await request<T>('delete', { url, opts });
  }

  export async function post<T>(
    url: string,
    body: Partial<T>,
    opts?: IHttpOpts
  ): Promise<T> {
    return await request<T>('post', { url, body, opts });
  }

  export async function put<T>(
    url: string,
    body: Partial<T>,
    opts?: IHttpOpts
  ): Promise<T> {
    return await request<T>('put', { url, body, opts });
  }

  async function request<T>(
    method: 'get' | 'post' | 'put' | 'delete',
    params: { url: string; body?: Partial<T>; opts?: IHttpOpts }
  ): Promise<T> {
    let callback;

    if (method === 'get' || method === 'delete') {
      callback = async () =>
        await axios[method](params.url, params?.opts?.config);
    } else {
      callback = async () =>
        await axios[method](params.url, params.body, params?.opts?.config);
    }

    return await errorHandler(callback, params.opts?.promptError);
  }
}

const errorHandler = async <T>(
  callBack: () => Promise<AxiosResponse<T>>,
  promptError?: boolean
): Promise<T | void> => {
  try {
    return await callBack().then((i) => i?.data);
  } catch (e: any) {
    const status = e?.response?.status;

    if (status === 403 || status === 401) {
      Modal.destroyAll();
      return;
    } else if (promptError) {
      message.error('Something went wrong. Please try again later.');
    }

    throw e;
  }
};
