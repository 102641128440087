import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  message,
  Pagination,
  Popconfirm,
  Space,
  Table,
} from "antd";
import {
  DeleteOutlined,
  SyncOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import RoleTag from "../../components/RoleTag";
import TableToolbar from "../../components/TableToolbar";
import CompanyTag from "../../components/CompanyTag";
import styled from "styled-components";
import InviteUserModal from "../../components/modals/InviteUserModal";
import { FilterValue, TablePaginationConfig } from "antd/es/table/interface";
import { ColumnType } from "antd/es/table";
import { PageHeader } from "@ant-design/pro-components";
import { UserRole } from "../../enums";
import { useGetCurrentUser } from "../../hooks/useGetCurrentUser";
import UserService from "../../services/user.service";
import {
  FilterField,
  User,
  UserListFilter,
  UserListFilterEnum,
} from "../../types";
import { FilterCard } from "../../components/shared/FilterCard";
import getClubInputOptions from "../../components/enums/getClubInputOptions";
import { useGetCompanies } from "../../hooks/useGetCompanies";

const routes = {
  items: [{ title: "Users" }, { title: "List" }],
};

const SPageHeader = styled(PageHeader)`
  border-bottom: 1px solid rgb(235, 237, 240);
  background: #fff;
`;

const SPageContent = styled.div`
  margin: 24px 24px 0;
  padding: inherit;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const UserList = () => {
  const columns: ColumnType<any>[] = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 140,
      sorter: true,
    },
    {
      title: "Club",
      dataIndex: "company",
      key: "company",
      width: 175,
      render: (company: any) => <CompanyTag company={company} />,
    },
    {
      title: "Role",
      key: "role",
      dataIndex: "role",
      width: 100,
      sorter: true,
      render: (role: UserRole) => <RoleTag role={role} />,
    },
  ];

  const [queryParams, setQueryParams] = useState("");
  const userService = useMemo(() => new UserService(), []);
  const { companies } = useGetCompanies();

  const getCachedFilter = () => {
    const odataCachedFilterString = localStorage.getItem(
      "odataUserListFilterString",
    );
    const odataFilterObj = localStorage.getItem("odataUserListFilterObj");
    return { odataCachedFilterString, odataFilterObj };
  };

  const [filter, setFilter] = useState<UserListFilter>(
    JSON.parse(getCachedFilter().odataFilterObj),
  );
  const [odataFilterString, setOdataFilterString] = useState<string>(
    getCachedFilter().odataCachedFilterString,
  );

  const { user } = useGetCurrentUser();

  const [users, setUsers] = useState<User[]>();
  const [usersCount, setUsersCount] = useState<number>();

  // console.log("users", users);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [pagination, setPagination] = useState({
    current: localStorage.userPage ?? 1,
    pageSize: localStorage.userPageSize ?? 10,
    total: 0,
  });

  const [pageCount, setPageCount] = useState({
    total: 0,
    startWindow: 0,
    endWindow: 0,
  });

  const [sorter, setSorter] = useState({
    field: undefined,
    order: undefined,
  });

  const [inviteModalVisible, setInviteModalVisible] = useState(false);

  const isUserAdmin = user.role === UserRole.admin;

  const adminFilterFields: FilterField[] = [
    {
      name: "companyId",
      label: "Club",
      type: "select",
      select: {
        mode: "multiple",
        filterOptionsBy: "display",
        options: getClubInputOptions(companies),
      },
    },
  ];

  const generalFilterFields: FilterField[] = [
    {
      name: "email",
      label: "Email or name",
      type: "text",
    },
  ];

  const getFilterFields = () => {
    if (isUserAdmin) {
      return [...adminFilterFields, ...generalFilterFields];
    }

    return generalFilterFields;
  };

  const fetchUsers = async () => {
    setLoading(true);
    console.log('query params', queryParams);
    const users = await userService.getUsers(queryParams);

    if (users && users.data && users.count !== undefined) {
      setUsers(users.data);
      setUsersCount(users.count);
    }
    setLoading(false);
  };

  useEffect(() => {
    // console.log("in fetch hook", queryParams);
    fetchUsers();
  }, [queryParams]);

  const deleteUser = async (payload: { id: string; email: string }) => {
    setLoading(true);
    const hide = message.loading("Deleting..", 1.0);

    if (!user.id) return;

    try {
      const deleteUser = await userService.deleteUser({
        id: payload.id,
        email: payload.email,
        userRole: user.role,
      });
      if (deleteUser) {
        fetchUsers();
        hide();
        setLoading(false);
        message.success("user deleted", 2.5);
      }
    } catch (err) {
      hide();
      setLoading(false);
      message.error("Error with deleting user", 2.5);
    }
  };

  if (isUserAdmin) {
    columns.push({
      title: "Actions",
      dataIndex: "role",
      key: "action",
      width: 75,
      render: (_: any, user: { id: string; email: string }) => (
        <Space size="middle">
          <Popconfirm
            title="Are you sure you would like to delete this user?"
            key={4}
            cancelButtonProps={{ disabled: loading }}
            icon={<DeleteOutlined />}
            onConfirm={() => deleteUser({ id: user.id, email: user.email })}
          >
            <Button disabled={loading} icon={<DeleteOutlined />}>
              Delete
            </Button>
          </Popconfirm>
        </Space>
      ),
    });
  }

  //update filter
  const updateFilterString = (newFilterObj: UserListFilter) => {
    // only the filter fields with actual values
    const filterBy = Object.entries(newFilterObj).reduce(
      (acc, [key, value]) => {
        if (value) {
          // check if the value is truthy & thus removing empty filters
          // @ts-ignore
          acc[key] = value; // add the key-value pair to the accumulator object
        }
        return acc;
      },
      {},
    ) as UserListFilter;

    let odataFilter: string[] = [];

    Object.keys(filterBy).forEach((field) => {
      if (field === UserListFilterEnum.email && !!filterBy.email!.trim()) {
        let emailFilterInput = filterBy.email!.trim().replace(/\s+/g, "");

        if (emailFilterInput) {
          // Construct the filter for email and name search
          let emailOrNameFilter = `(contains(tolower(email), tolower('${emailFilterInput}')) or contains(tolower(name), tolower('${emailFilterInput}')))`;

          odataFilter.push(`${emailOrNameFilter}`);
        }
      } else if (field === UserListFilterEnum.companyId) {
        let multipleClubIds = newFilterObj[field]
          .map((clubId) => {
            return `companyId eq '${clubId}'`;
          })
          .join(" or ");
        odataFilter.push(`(${multipleClubIds})`);
      }
    });

    // console.log("odataFilter ==>", odataFilter);

    //set current filter obj state
    setFilter(filterBy);
    setOdataFilterString(odataFilter.join(" and "));

    //store odataFilterString in cache
    localStorage.setItem(
      "odataUserListFilterString",
      odataFilter.join(" and "),
    );

    //store odataFilterObj in cache
    localStorage.setItem("odataUserListFilterObj", JSON.stringify(filterBy));
  };

  const buildODataQuery = () => {
    const odataQueryParams = [];

    // Pagination
    // OData uses $skip and $top for pagination
    odataQueryParams.push(
      `$skip=${(pagination.current - 1) * pagination.pageSize}`,
    );
    odataQueryParams.push(`$top=${pagination.pageSize}`);

    // Sorting
    if (sorter && sorter.field) {
      // Mapping Ant Design's sorting to OData
      const order = sorter.order === "descend" ? "desc" : "asc";
      odataQueryParams.push(`$orderby=${sorter.field} ${order}`);
    }

    // filter out archived users and add the odataFilterString (if provided)
    const filterOutArchivedUsers = `$filter=(role ne 'archived_legacy' and role ne 'archived'`;
    odataQueryParams.push(
      `${filterOutArchivedUsers}
      ${odataFilterString ? `and ${odataFilterString}` : ""}
      )`,
    );

    // console.log("FINAL => ", `?${odataQueryParams.join("&")}`);

    setQueryParams(`?${odataQueryParams.join("&")}`);
  };

  const buildTableData = () => {
    if (users) {
      let outp = [];
      for (let i = 0; i < users.length; i++) {
        let item = {
          key: i,
          ...users[i],
        };
        outp.push(item);
      }

      setData(outp);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      (pagination.pageSize && pagination.current && pagination.total) ||
      sorter.field ||
      odataFilterString
    ) {
      // console.log("in main hook", sorter);
      buildODataQuery();

      setPageCount({
        total: pagination.total,
        startWindow: Math.min(
          usersCount,
          ((pagination.current || 1) - 1) * (pagination.pageSize || 10) + 1,
        ),
        endWindow: Math.min(
          pagination.total,
          pagination.current * (pagination.pageSize || 10),
        ),
      });
    }
  }, [
    pagination.pageSize,
    pagination.current,
    pagination.total,
    sorter?.field,
    sorter?.order,
    odataFilterString,
  ]);

  //fetch data
  useEffect(() => {
    if (users) {
      buildTableData();
    }

    if (usersCount) {
      setPagination({ ...pagination, total: usersCount });
    }
  }, [users, usersCount]);

  const handleTableChange = (
    _: TablePaginationConfig, // do not use, pagination is being controlled by Pagination component
    __?: Record<string, FilterValue>,
    sorter?: any,
  ) => {
    setSorter(sorter);
  };

  const handlePageChange = (current: any, pageSize: any) => {
    // set current userPage in localstorage
    localStorage.setItem("userPage", current);
    localStorage.setItem("userPageSize", pageSize);

    setPagination({
      ...pagination,
      current,
      pageSize,
    });
  };

  const emptyFilterState = [...generalFilterFields, ...adminFilterFields].map(
    (field) => ({
      // it will complain that you cannot assign undefined
      // to "state" and "type" because they are enums
      // @ts-ignore
      [field.name]: undefined,
    }),
  );

  const clearFilter = () => {
    localStorage.removeItem("odataUserListFilterString");
    localStorage.removeItem("odataUserListFilterObj");

    // @ts-ignore
    setFilter(...emptyFilterState);

    // @ts-ignore
    updateFilterString(...emptyFilterState);
  };

  return (
    <>
      {inviteModalVisible && (
        <InviteUserModal
          isModalVisible={inviteModalVisible}
          setIsModalVisible={setInviteModalVisible}
          onUserCreated={() => {
            setInviteModalVisible(!inviteModalVisible);
            fetchUsers();
          }}
        />
      )}
      <SPageHeader
        title="Users"
        breadcrumb={routes}
        extra={[
          isUserAdmin && (
            <Button
              key={1}
              onClick={() => setInviteModalVisible(true)}
              icon={<UsergroupAddOutlined />}
            >
              Invite
            </Button>
          ),
        ]}
      />
      <SPageContent>
        <FilterCard
          layout={{ lg: 8, md: 12, sm: 24, xs: 24 }}
          filterFields={getFilterFields()}
          filter={filter}
          setFilter={updateFilterString}
          clearFilter={clearFilter}
        />
        <Card bodyStyle={{ paddingTop: "0" }} style={{ minWidth: "700px" }}>
          <TableToolbar
            title=""
            buttons={[
              <Button
                key={0}
                onClick={() => fetchUsers()}
                icon={<SyncOutlined />}
              ></Button>,
            ]}
          />
          <Table
            rowKey={(record) => record.id}
            columns={columns}
            dataSource={data}
            pagination={false}
            loading={loading}
            onChange={handleTableChange}
            footer={() =>
              `${pageCount.startWindow}-${pageCount.endWindow} of ${pageCount.total} total record(s)`
            }
          />
          <Pagination
            style={{ marginTop: "10px", marginBottom: "10px" }}
            showSizeChanger
            defaultCurrent={pagination.current}
            // Infinity otherwise won't set the default starting page to 1
            total={usersCount}
            onChange={handlePageChange}
          />
        </Card>
      </SPageContent>
    </>
  );
};

export default UserList;
