import axios from 'axios';

export default class CompanyService {
  async getCompanies(options?: {
    onlyPLClubs?: boolean;
    onlyClubs?: boolean;
    onlyClubsAndDefault?: boolean;
  }) {
    let filterString = `/?$filter=`;
    const filterOptions = [];

    const hasFilteringOptions = Object.values(options).some((value) => !!value);

    if (hasFilteringOptions) {
      if (options?.onlyPLClubs) {
        filterOptions.push(`(clubInPremierLeague eq true and companyType eq 'club')`);
      }
      if (options?.onlyClubsAndDefault) {
        filterOptions.push(
          `(companyType eq 'club' or companyType eq 'default')`,
        );
      }
      if (options?.onlyClubs) {
        filterOptions.push(
          `(companyType eq 'club')`,
        );
      }
    }
    filterString += filterOptions.join(' and ');

    try {
      const response = await axios.get(
        `/company${hasFilteringOptions ? filterString : ''}`,
      );
      if (response.data) {
        return {
          ...response.data,
        };
      }
      return null;
    } catch (error) {
      console.error('Error in getCompanies:', error);
      throw error;
    }
  }
}
