import { Result, Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const NoFoundPage = () => {
  const navigate = useNavigate();

  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you don't have access to this page. Click  below to login."
      extra={[
        <Button type="primary" onClick={() => navigate("/")} key="home">
          Login
        </Button>,
      ]}
    />
  );
};

export default NoFoundPage;
