import axios from 'axios';
import { User } from '../types';

export default class AttachmentService {
  // gets attachments, with filters and pagination
  async getAttachmentsForTerm(termId: string) {
    try {
      const response = await axios.get(`/attachments/termId/${termId}`);
      if (response.data) {
        return {
          ...response.data,
        };
      }
      return null;
    } catch (error) {
      console.error('Error in getAttachments:', error);
      throw error;
    }
  }

  // shares attachment
  async shareAttachment(payload: { fileId: string; user: User }) {
    const { fileId, user } = payload;

    try {
      const response = await axios.post(`/attachments/upload/share`, {
        fileId,
        user,
      });

      if (response) {
        return response;
      }
      return null;
    } catch (error) {
      console.error('Error in getAttachment:', error);
      throw error;
    }
  }

  // deletes attachment
  async deleteAttachment(payload: { fileId: string }) {
    const { fileId } = payload;

    try {
      const response = await axios.post(`/attachments/upload/delete/${fileId}`);

      if (response) {
        return response;
      }
    } catch (error) {
      console.error('Error in deleteAttachment:', error);
      throw error;
    }
  }
}
