import axios from 'axios';

export default class AuditService {
  // gets terms, with filters and pagination
  async getAudits(queryParams: string = '') {
    try {
      const response = await axios.get(`/audit${queryParams}`);
      if (response.data) {
        return {
          ...response.data,
        };
      }
      return null;
    } catch (error) {
      console.error('Error in getAudits:', error);
      throw error;
    }
  }
}
