"use client";

import React, { useEffect, useState } from "react";
import { AuthProvider } from "oidc-react";
import { AuthContext, oidcConfig } from "./utils/authUtils";
import { AllRoutes } from "./routes/AllRoutes";
import { permissionManager } from "./services/permission_manager";
import { cdbTheme } from "./cdbTheme";
import { ConfigProvider, Spin } from "antd";

const App = () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    permissionManager.init().then((res) => {
      setLoaded(true);
    });
  }, []);

  return (
    <ConfigProvider theme={cdbTheme}>
      <AuthProvider {...oidcConfig}>
        <AuthContext>
          {loaded ? (
            <AllRoutes />
          ) : (
            <Spin style={{ position: "absolute", top: "50%", left: "50%" }} />
          )}
        </AuthContext>
      </AuthProvider>
    </ConfigProvider>
  );
};

export default App;
