import env from "@beam-australia/react-env";
import { useAuth, UserManager } from "oidc-react";
import axios from "axios";
import React from "react";
import Loading from "@ant-design/pro-card/lib/components/Loading";
import { AuthProviderProps } from "oidc-react/build/src/AuthContextInterface";
import { WebStorageStateStore } from "oidc-client-ts";

export const oidcConfig: AuthProviderProps = {
  onSignIn: async (user: any) => {
    window.location.search = "";
  },
  // this is needed to store the token into the local storage rather than
  // the session storage, so that opening a term into a new tab would not
  // re-trigger the login process and redirect to /transaction homepage
  userManager: new UserManager({
    authority: env("PLAS_URL"),
    client_id: env("PLAS_CLIENT_ID"),
    redirect_uri: env("PUBLIC_URL"),
    scope: env("SCOPE"),
    response_type: "code",
    automaticSilentRenew: false,
    // @ts-ignore
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  }),
};

export const AuthContext = ({ children }: { children: JSX.Element }) => {
  const auth = useAuth();

  if (auth && auth.isLoading) return <Loading />;

  if (auth && auth.userData && auth.userData.access_token) {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${auth.userData.access_token.toString()}`;
  }

  return children;
};
