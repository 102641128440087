import React from "react";

import { Button, Result } from "antd";

const Logout = () => {
  return (
    <Result
      status="success"
      title=""
      subTitle="You have been logged out successfully. Feel free to close this window."
      extra={[
        <Button type="primary" href="/" key="login">
          Return to Login
        </Button>,
      ]}
    />
  );
};

export default Logout;
