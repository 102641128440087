import {
  Avatar,
  Button,
  Card,
  Col,
  DatePicker,
  Flex,
  Form,
  List,
  Radio,
  Row,
  Select,
  Space,
  theme,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import styled from "styled-components";
import TermService from "../../../services/term.service";
import { Company, Term, TransactionsPerCLubFilter } from "../../../types";
import { TermStatus, TermType } from "../../../enums";
import { useGetCompanies } from "../../../hooks/useGetCompanies";
import { ColumnConfig } from "@ant-design/plots/es/components/column";
import {
  capitalizeFirstLetter, logoResolver,
  sortDataByTermTypeEnumOrder,
} from "../../../utils";
import { ClearOutlined, ExportOutlined } from "@ant-design/icons";
import html2canvas from "html2canvas";
import { useGetCsvData } from "../../../hooks/useGetCsvData";
import { Column, Pie, PieConfig } from "@ant-design/charts";

const { RangePicker } = DatePicker;

type TermCountData = { type: TermType; count: number };

const SRow = styled(Row)`
  margin: 16px 0;
  background: "red";
`;

const { Option } = Select;

const SOptionLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  > span {
    width: 18px;
    height: 18px;
  }
`;

type TransactionColumnData = {
  clubId: string;
  clubName: string;
  value: number;
  type: string;
};

export const TransactionsPerClub = () => {
  const [form] = Form.useForm();
  const [csvQueryParams, setCsvQueryParams] = useState("");
  const [isExportingGraph, setIsExportingGraph] = useState(false);

  const { csvData } = useGetCsvData(`${csvQueryParams}`, "terms");

  const sixMonthsAgo = dayjs()
    .subtract(5, "month")
    .startOf("day")
    .format("YYYY/MM/DD");

  const endOfWeek = dayjs().endOf("week").format("YYYY/MM/DD");

  const { companies } = useGetCompanies({
    onlyClubs: true,
  });

  const emptyFilterState = {
    transactionType: "all",
    companyId: "",
    transactionStatus: "all",
    submittedToPlDate: [sixMonthsAgo, endOfWeek],
  } as TransactionsPerCLubFilter;

  const getCachedFilter = () => {
    const cachedFilter = localStorage.getItem("transactionsPerClubGraphFilter");
    if (cachedFilter) {
      return JSON.parse(cachedFilter);
    }

    return { ...emptyFilterState };
  };

  const onClearFilter = () => {
    //clear filter
    localStorage.removeItem("transactionsPerClubGraphFilter");

    setSelectedCompanyInfo(undefined);

    setFilter(emptyFilterState);
  };

  const [filter, setFilter] = useState<TransactionsPerCLubFilter>(
    getCachedFilter(),
  );

  const termService = useMemo(() => new TermService(), []);

  const [transactionsPerClub, setTransactionsPerClub] = useState<
    TransactionColumnData[]
  >([]);

  const [historicTransactionsCount, setHistoricTransactionsCount] =
    useState<number>();
  const [
    historicTransactionsPerClubCount,
    setHistoricTransactionsPerClubCount,
  ] = useState<number>();
  const [totalTermsCount, setTotalTermsCount] = useState<TermCountData[]>();

  const [selectedCompanyInfo, setSelectedCompanyInfo] = useState<{
    company?: Company;
    transactionData?: TermCountData[];
  }>();

  const [fetchingTransactionsPerClub, setFetchingTransactionsPerClub] =
    useState(false);

  const countTermsByType = (
    terms: Term[],
    type: TermType,
    getHistorical?: boolean,
  ) => {
    let count: number;

    if (!getHistorical) {
      count = terms.filter((term) => term.type === type).length;
    } else {
      count = terms.filter(
        (term) => term.type === type && term.state === TermStatus.historical,
      ).length;
    }

    // without this IF, the historical trans. count would overwrite the total count for that type
    // this will need to be refactored though, if one day we decide to track historical trans. of any other type but databank
    if (!getHistorical) {
      // console.log(selectedCompanyInfo);
      setSelectedCompanyInfo((prevInfo) => {
        // Create a copy of the original array
        let updatedData = (prevInfo?.transactionData || []).slice();

        const existingIndex = updatedData.findIndex((td) => td.type === type);

        if (existingIndex !== -1) {
          updatedData[existingIndex] = { type, count };
        } else {
          updatedData.push({ type, count });
        }

        // if filtering by a specific type, only store that transactionData type
        if (filter.transactionType !== "all") {
          updatedData = updatedData.filter(
            (data) => data.type === filter.transactionType,
          );
        }

        return {
          ...prevInfo,
          transactionData: updatedData,
        };
      });
    } else {
      setHistoricTransactionsPerClubCount(count);
    }
    return count;
  };

  const createTransactionData = (club: Company, clubTerms: Term[]) => [
    {
      clubId: club?.id,
      clubName: club?.name,
      value: countTermsByType(clubTerms, TermType.databank),
      type: capitalizeFirstLetter(TermType.databank),
    },
    {
      clubId: club?.id,
      clubName: club?.name,
      value: countTermsByType(clubTerms, TermType.databank, true),
      type: capitalizeFirstLetter(TermStatus.historical),
    },
    {
      clubId: club?.id,
      clubName: club?.name,
      value: countTermsByType(clubTerms, TermType.threshold),
      type: capitalizeFirstLetter(TermType.threshold),
    },
    {
      clubId: club?.id,
      clubName: club?.name,
      value: countTermsByType(clubTerms, TermType.associated),
      type: capitalizeFirstLetter(TermType.associated),
    },
  ];

  const getTransactionsPerClub = async () => {
    setFetchingTransactionsPerClub(true);

    const from = dayjs(filter.submittedToPlDate[0]).toISOString();
    const to = dayjs(filter.submittedToPlDate[1]).toISOString();

    const type = filter.transactionType !== "all" ? filter.transactionType : "";
    const state =
      filter.transactionStatus !== "all" ? filter.transactionStatus : "";

    const queryParams = `?$filter=(submissionDate ge ${from} and submissionDate le ${to})${
      filter.companyId ? ` and companyId eq ${filter.companyId}` : ""
    }${type ? ` and type eq '${TermType[type]}'` : ""}${
      state ? ` and state eq '${TermStatus[state]}'` : ""
    }`;

    // store filters in cache
    localStorage.setItem(
      "transactionsPerClubGraphFilter",
      JSON.stringify(filter),
    );

    setCsvQueryParams(queryParams);

    const terms = await termService.getTerms(queryParams);

    setHistoricTransactionsCount(
      terms?.data?.filter(
        (t: Term) =>
          t.type === TermType.databank && t.state === TermStatus.historical,
      ).length,
    );

    // set general terms count
    if (filter.transactionType === "all") {
      setTotalTermsCount([
        {
          type: TermType.databank,
          count: terms?.data?.filter((t: Term) => t.type === TermType.databank)
            .length,
        },
        {
          type: TermType.threshold,
          count: terms?.data?.filter((t: Term) => t.type === TermType.threshold)
            .length,
        },
        {
          type: TermType.associated,
          count: terms?.data?.filter(
            (t: Term) => t.type === TermType.associated,
          ).length,
        },
      ]);
    } else {
      // if filtering by a specific type, only store that transactionData type
      setTotalTermsCount([
        {
          type: type as TermType,
          count: terms?.data?.filter((t: Term) => t.type === (type as TermType))
            .length,
        },
      ]);
    }

    let transactionsPerClub: TransactionColumnData[][];

    // showing all companies
    if (!filter.companyId) {
      transactionsPerClub = companies.map((club) => {
        const clubTerms = terms.data.filter(
          (term: Term) => term.company.id === club.id,
        );
        return createTransactionData(club, clubTerms);
      });
    } else {
      // showing selected company
      const club = companies.find((c) => c.id === filter.companyId);
      const clubTerms = terms.data.filter(
        (term: Term) => term.company.id === filter.companyId,
      );
      transactionsPerClub = [createTransactionData(club, clubTerms)];
    }

    setFetchingTransactionsPerClub(false);
    setTransactionsPerClub(transactionsPerClub.flat());
  };

  const TransactionsPerClubColumn = () => {
    const config: ColumnConfig = {
      data: transactionsPerClub,
      xField: "clubName",
      yField: "value",
      seriesField: "type",
      isGroup: true,
      columnStyle: {
        radius: [20, 20, 0, 0],
        width: 2,
      },
      maxColumnWidth: 10,
      yAxis: {
        label: {
          formatter: (tick: any) => {
            if (tick % 1 === 0) {
              return tick;
            }
          },
        },
      },
      xAxis: {
        label: {
          autoHide: false,
          autoRotate: true,
          autoEllipsis: true,
        },
      },
      color: ["#4CAF50", "#9b59b6", "#FFC107", "#2196F3"],
      appendPadding: [30, 0, 0, 0],
    };

    return <Column {...config} />;
  };

  const TransactionsTypePie = () => {
    const data = selectedCompanyInfo?.company?.id
      ? selectedCompanyInfo?.transactionData
      : sortDataByTermTypeEnumOrder(totalTermsCount) || [];

    const config: PieConfig = {
      data,
      angleField: "count",
      tooltip: {
        showTitle: false,
        formatter: (datum) => ({
          name: capitalizeFirstLetter(datum.type),
          value: datum.count,
        }),
      },
      colorField: "type",
      innerRadius: 0.4,
      legend: {
        position: "left",
        itemSpacing: 10,
        itemName: {
          formatter: (text) => {
            return capitalizeFirstLetter(text);
          },
        },
      },
      label: {
        type: "inner",
        offset: "-50%", // Adjust as needed to position the label inside
        autoRotate: false,
        formatter: (datum: any) => {
          return `${(datum.percent * 100).toFixed(2)}%`;
        },
        style: {
          fontSize: 16,
          fontWeight: "bold",
          textAlign: "center",
        },
      },
      color: (datum: any, _?: string) => {
        switch (datum.type) {
          case TermType.databank:
            return "#4CAF50";
          case TermType.threshold:
            return "#FFC107";
          case TermType.associated:
            return "#2196F3";
        }
      },
    };

    return <Pie {...config} />;
  };

  const onConfirmDateRange = () => {
    const val = form.getFieldValue("submittedToPl");

    const dates = (val || filter.submittedToPlDate).map((date: string) =>
      dayjs(date).utc().format("YYYY/MM/DD"),
    );
    setFilter({ ...filter, submittedToPlDate: dates });
  };

  const onSelectClubFilter = (id: string) => {
    const company = companies.find((c) => c.id === id);

    if (company) {
      setSelectedCompanyInfo({
        ...selectedCompanyInfo,
        company,
      });
    }
    setFilter({ ...filter, companyId: id });
  };

  // this is needed to trigger the company panel when refreshing the page
  // and there is a selected company id in the cached filter
  useEffect(() => {
    if (filter.companyId && companies.length > 0) {
      onSelectClubFilter(filter.companyId);
    }
  }, [filter.companyId, companies]);

  useEffect(() => {
    getTransactionsPerClub();
  }, [companies, filter]);

  const onExportGraphData = async () => {
    setIsExportingGraph(true);
    try {
      const response = await termService.exportGraph({
        odataQuery: csvQueryParams,
      });

      // Create a Blob URL from the response data
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a download link
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Transactions-Per-Club-Report-${dayjs(new Date()).format(
          "DD-MM-YYYY LT",
        )}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      setIsExportingGraph(false);
    } catch (error) {
      console.error(error);
      setIsExportingGraph(false);
    }
  };

  // console.log("filter", filter);

  return (
    <Form form={form}>
      <SRow>
        <Card
          id="transactionsPerClubGraph"
          actions={[
            <Button
              icon={<ExportOutlined />}
              loading={isExportingGraph}
              onClick={onExportGraphData}
            >
              Export
            </Button>,
          ]}
          size={"small"}
          title="Transactions per Club"
          style={{ flex: "auto" }}
        >
          <SRow justify={"start"} gutter={48}>
            <Col lg={10} md={24} sm={24} xs={24}>
              <Flex flex="auto">
                {/*club filter*/}
                <Form.Item label="Club">
                  <Select
                    style={{ minWidth: 300 }}
                    allowClear
                    onClear={() => {
                      setFilter({ ...filter, companyId: undefined });
                      setSelectedCompanyInfo(undefined);
                    }}
                    value={filter.companyId}
                    defaultValue={filter.companyId}
                    onSelect={onSelectClubFilter}
                    showSearch
                    placeholder="Search to Select"
                    optionFilterProp="display"
                    filterOption={(input, option) =>
                      option.display
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {companies?.map((company: Company, index: number) => (
                      <Option
                        key={index}
                        value={company.value}
                        display={company.display}
                      >
                        <SOptionLogoWrapper>
                          <Avatar
                            size="small"
                            src={`${logoResolver(company.logo)}`}
                            alt="avatar"
                          />
                          {company.display}
                        </SOptionLogoWrapper>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Flex>
            </Col>
            <Col lg={14} md={24} sm={24} xs={24}>
              {filter?.submittedToPlDate && (
                <Flex gap={"middle"}>
                  <Form.Item label="Submitted to PL" name={"submittedToPl"}>
                    <RangePicker
                      defaultValue={[
                        dayjs(filter?.submittedToPlDate[0], "YYYY-MM-DD"),
                        dayjs(filter?.submittedToPlDate[1], "YYYY-MM-DD"),
                      ]}
                    />
                  </Form.Item>
                  <Button type="primary" onClick={onConfirmDateRange}>
                    Apply date range
                  </Button>
                </Flex>
              )}
            </Col>
          </SRow>

          <SRow justify={"start"} gutter={48}>
            {/*type filter*/}
            <Col lg={10} md={24} sm={24} xs={24}>
              <Form.Item label="Type">
                <Radio.Group
                  onChange={(e: any) =>
                    setFilter({
                      ...filter,
                      transactionType: e.target.value as TermType & "all",
                    })
                  }
                  value={filter.transactionType}
                  style={{ marginBottom: "30px" }}
                >
                  <Radio.Button value="all">All</Radio.Button>
                  <Radio.Button value={TermType.databank}>
                    Databank
                  </Radio.Button>
                  <Radio.Button value={TermType.threshold}>
                    Threshold
                  </Radio.Button>
                  <Radio.Button value={TermType.associated}>
                    Associated Party
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            {/*status filter*/}
            <Col span={12}>
              <Form.Item label="Status">
                <Radio.Group
                  onChange={(e: any) =>
                    setFilter({
                      ...filter,
                      transactionStatus: e.target.value as TermStatus & "all",
                    })
                  }
                  value={filter.transactionStatus}
                  style={{ marginBottom: "30px" }}
                >
                  <Radio.Button value="all">All</Radio.Button>
                  <Radio.Button value={TermStatus.approved}>
                    Approved
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </SRow>
          {/*clear filter button*/}
          <SRow>
            <Col span={24} style={{ textAlign: "right" }}>
              <Space>
                {filter && Object.keys(filter).length > 0 && (
                  <Button
                    key={1}
                    onClick={onClearFilter}
                    icon={<ClearOutlined />}
                  >
                    Clear
                  </Button>
                )}
              </Space>
            </Col>
          </SRow>

          {!selectedCompanyInfo?.company?.id && (
            <SRow gutter={48}>
              <Col span={24}>
                <Card>
                  <Col span={24}>
                    <List
                      grid={{ gutter: 16, column: 3 }}
                      dataSource={sortDataByTermTypeEnumOrder(totalTermsCount)}
                      renderItem={(item, index) => (
                        <List.Item key={index}>
                          <Card
                            size={"small"}
                            title={`${capitalizeFirstLetter(item?.type)}
                          ${
                            item.type === TermType.databank
                              ? "(Historical)"
                              : ""
                          }`}
                          >
                            {item?.count}{" "}
                            <strong>
                              {item.type === TermType.databank
                                ? `(${historicTransactionsCount})`
                                : ""}
                            </strong>
                          </Card>
                        </List.Item>
                      )}
                    />
                  </Col>
                  <Col span={24}>
                    <TransactionsTypePie />
                  </Col>
                </Card>
              </Col>
            </SRow>
          )}

          <Flex>
            <Card style={{ flex: "3" }} loading={fetchingTransactionsPerClub}>
              <TransactionsPerClubColumn />
            </Card>
            {selectedCompanyInfo?.company?.id && (
              <Card style={{ flex: "2" }} loading={fetchingTransactionsPerClub}>
                <Flex justify="center">
                  <img
                    style={{ height: 150 }}
                    src={`${selectedCompanyInfo?.company?.logo}`}
                    alt={selectedCompanyInfo?.company?.name}
                  />
                </Flex>
                <List
                  itemLayout="horizontal"
                  dataSource={sortDataByTermTypeEnumOrder(
                    selectedCompanyInfo?.transactionData,
                  )}
                  renderItem={(item, index) => (
                    <List.Item key={index}>
                      <List.Item.Meta
                        title={
                          <a href="https://ant.design">
                            {capitalizeFirstLetter(item?.type)}{" "}
                            {item.type === TermType.databank
                              ? "(Historical)"
                              : ""}
                          </a>
                        }
                        description={
                          <span>
                            {item?.count}{" "}
                            <strong>
                              {item.type === TermType.databank
                                ? `(${historicTransactionsPerClubCount})`
                                : ""}
                            </strong>
                          </span>
                        }
                      />
                    </List.Item>
                  )}
                />
              </Card>
            )}
          </Flex>
        </Card>
      </SRow>
    </Form>
  );
};
