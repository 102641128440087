import { useEffect, useMemo, useState } from 'react';
import { Term, User } from '../types';
import UserService from '../services/user.service';
import { hydrateCompanies } from '../utils';

export const useGetCurrentUser = () => {
  const [user, setUser] = useState<User | undefined>({} as User);
  const [isFetchingUser, setIsFetchingAppUser] = useState<boolean>(false);

  const userService = useMemo(() => new UserService(), []);

  const fetchCurrentUser = async () => {
    setIsFetchingAppUser(true);
    const currentUser = await userService.getCurrentUser();

    const currentUserCompanyHydrated = {
      ...currentUser,
      company: hydrateCompanies(currentUser.company),
    };

    setUser(currentUserCompanyHydrated);
    setIsFetchingAppUser(false);
  };

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  const refetchUser = () => {
    fetchCurrentUser();
  };
  return { user, refetchUser, isFetchingUser };
};
