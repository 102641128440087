import React from "react";
import { Flex, Tag, Tooltip } from "antd";
import { TermType } from "../enums";
import {
  ExclamationCircleOutlined,
  ExclamationOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

const TypeTag = ({
  type,
  convertedOn,
}: {
  type: TermType;
  convertedOn?: string;
}) => {
  const getDisplay = () => {
    switch (type) {
      case TermType.threshold:
        return "Threshold";
      case TermType.databank:
        return "Databank";
      case TermType.associated:
        return "Assoc. Party";
    }
  };

  const typeEnum = Object.fromEntries(
    Object.keys(TermType).map((type) => [
      [type],
      {
        color: "pink",
        display: getDisplay(),
      },
    ]),
  );

  const typeResult = typeEnum[type] || {
    color: "blue",
    display: "n/a",
  };

  return (
    <Flex>
      <Tag color={typeResult.color}>{typeResult.display}</Tag>
      {convertedOn ? (
        <Tooltip
          title={`Converted to ${typeResult.display} transaction on ${dayjs(
            convertedOn,
          ).format("DD-MM-YYYY")}.`}
          color={typeResult.color}
          key={typeResult.color}
        >
          <ExclamationCircleOutlined />
        </Tooltip>
      ) : null}
    </Flex>
  );
};

export default TypeTag;
