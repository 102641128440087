import React, { useEffect, useState } from "react";
import { Descriptions, Select, Spin, Timeline } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import {
  Audit,
  AuditFilter,
  AuditFilterEnum,
  AuditFilterForm,
  AuditType,
  FilterField,
} from "../types";
import { useParams } from "react-router-dom";
import AuditService from "../services/audit.service";
import { UserRole } from "../enums";
import { DeletedUser } from "./shared/DeletedUser";
import { FilterCard } from "./shared/FilterCard";
import { getLabelForAuditType } from "../utils";
const { Option } = Select;

const SPageContent = styled.div`
  margin: 24px 24px 0;
  padding: inherit;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const SCommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 50px;

  > ul {
    min-width: 650px;
  }

  .ant-comment-inner {
    padding: 0;
  }
`;

const labelToColor = (label: string) => {
  switch (label) {
    case "Update term":
      return "red";
    case "Get term":
      return "green";
    default:
      return "purple";
  }
};

const labelToContent = (label: string) => {
  switch (label) {
    case "Create term":
      return "Submitted the transaction";
    case "Update term":
      return "Updated the transaction";
    case "Get term":
      return "Viewed the transaction";
    default:
      return "";
  }
};

const auditToContent = (audit: Audit) => {
  if (audit.label === "Update term") {
    const parsedDescription = JSON.parse(audit.description);

    const descriptionItems = Object.keys(parsedDescription).map(
      (field: string, key: number) => ({
        key,
        label:
          field.charAt(0).toUpperCase() + field.slice(1).replace(/_/g, " "),
        children: (
          <div>
            <b>Before:</b>
            <br />
            {parsedDescription[field]?.before}
            <br />
            <b>After:</b>
            <br />
            {parsedDescription[field]?.after}
            <br />
          </div>
        ),
      }),
    );

    return (
      <Descriptions
        title={labelToContent(audit.label)}
        size="small"
        bordered
        items={descriptionItems}
        layout="horizontal"
        column={1}
        labelStyle={{ width: 250 }}
      />
    );
  } else {
    return (
      <Descriptions
        title={labelToContent(audit.label)}
        size={"small"}
        bordered
      ></Descriptions>
    );
  }
};

const AuditItem = ({ audit }: { audit: Audit }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <span style={{ color: "grey", fontSize: 12 }}>
        {audit.user?.role === UserRole.archived_legacy ||
        audit.user?.role === UserRole.archived ? (
          <DeletedUser />
        ) : null}
        {audit.userEmail}{" "}
        {dayjs(audit.createdAt).format("Do MMM, YYYY, h:mm:ss a")}
      </span>
      <span style={{ color: "grey", fontSize: 14 }}>
        {auditToContent(audit)}
      </span>
    </div>
  );
};

const AuditsList = ({ audits }: { audits: Audit[] }) => {
  return (
    <Timeline
      mode="left"
      items={audits.map((audit: Audit, i: number) => ({
        key: i,
        color: labelToColor(audit.label),
        children: <AuditItem key={audit.id} audit={audit} />,
      }))}
    />
  );
};

const AuditLog = () => {
  const params = useParams();
  const auditService = new AuditService();
  const [queryParams, setQueryParams] = useState("");
  const [audits, setAudits] = useState<Audit[]>();
  const [isFetchingAudits, setIsFetchingAudits] = useState(false);

  const fetchAudits = async () => {
    setIsFetchingAudits(true);
    const audits = await auditService.getAudits(queryParams);

    if (audits && audits.data) {
      setAudits(audits.data);
      setIsFetchingAudits(false);
    }
  };

  const getCachedFilter = () => {
    const odataCachedFilterString = localStorage.getItem(
      "odataAuditFilterString",
    );
    const odataFilterObj = localStorage.getItem("odataAuditFilterObj");
    return { odataCachedFilterString, odataFilterObj };
  };

  const [filter, setFilter] = useState<AuditFilter>({} as AuditFilter);

  const [odataFilterString, setOdataFilterString] = useState<string>();

  const id = params.id;

  const buildODataQuery = () => {
    const odataQueryParams = [];

    // push filter string
    if (!!odataFilterString) {
      odataQueryParams.push(odataFilterString);
    }

    setQueryParams(
      `?$orderby=createdAt desc&$filter=(${odataQueryParams.join(" and ")})`,
    );

    // console.log("FINAL odataFilterString => ", `${odataFilterString}`);
    // console.log("FINAL odataQueryParams => ", odataQueryParams);
    // console.log(
    //   "FINAL REQ => ",
    //   `?$orderby=createdAt desc&$filter=(${odataQueryParams.join(" and ")})`,
    // );
  };

  useEffect(() => {
    if (!!queryParams) {
      // console.log("queryParams", queryParams);
      fetchAudits();
    }
  }, [queryParams]);

  useEffect(() => {
    if (!!odataFilterString) {
      buildODataQuery();
    }
  }, [odataFilterString]);

  useEffect(() => {
    if (id) {
      const { odataFilterObj } = getCachedFilter();

      // check if there are filters in the cache
      const filter = JSON.parse(odataFilterObj)?.type
        ? JSON.parse(odataFilterObj)
        : // or set the default filter to type response
          { type: AuditType.all };

      setFilter(filter);
      updateFilterString(filter);
    }
  }, [id]);

  //update filter
  const updateFilterString = (newFilterObj: AuditFilter) => {
    if (id && newFilterObj) {
      const filterBy = Object.entries(newFilterObj).reduce(
        (acc, [key, value]) => {
          if (value) {
            // check if the value is truthy & thus removing empty filters
            // @ts-ignore
            acc[key] = value; // add the key-value pair to the accumulator object
          }
          return acc;
        },
        {},
      ) as AuditFilter;

      let odataFilter: string[] = [];

      Object.keys(filterBy).forEach((field) => {
        if (field === AuditFilterEnum.type) {
          const viewsOnly = `contains(action, 'GET') and ${field} eq 'response'`;
          const changesOnly = `contains(action, 'PATCH') and ${field} eq 'response' or contains(action, 'POST') and ${field} eq 'response'`;
          const all = `${viewsOnly} or ${changesOnly}`;

          switch (newFilterObj[field]) {
            case AuditType.views:
              odataFilter.push(`(${viewsOnly})`);
              break;
            case AuditType.changes:
              odataFilter.push(`(${changesOnly})`);
              break;
            case AuditType.all:
              odataFilter.push(`(${all})`);
              break;
          }
        }
      });

      // push filter by current term id
      odataFilter.push(`termId eq '${id}'`);

      //set current filter obj state
      setFilter(filterBy);
      setOdataFilterString(odataFilter.join(" and "));

      //store odataFilterString in cache
      localStorage.setItem("odataAuditFilterString", odataFilter.join(" and "));

      //store odataFilterObj in cache
      localStorage.setItem("odataAuditFilterObj", JSON.stringify(filterBy));
    }
  };

  const auditFilterFields: FilterField[] = [
    {
      name: "type",
      label: "Type",
      type: "select",
      select: {
        options: (
          <>
            {Object.keys(AuditType).map((auditType, index) => {
              return (
                <Option key={index} value={auditType}>
                  {getLabelForAuditType(auditType as AuditType)}
                </Option>
              );
            })}
          </>
        ),
      },
    },
  ];

  const clearFilter = () => {
    let emptyFilterState = [...auditFilterFields].map((field) => ({
      // it will complain that you cannot assign undefined
      // to "state" and "type" because they are enums
      // @ts-ignore
      [field.name]: undefined,
    }));

    // @ts-ignore
    setFilter(...emptyFilterState);
  };

  return (
    <SPageContent>
      <FilterCard
        layout={{ lg: 6, md: 12, sm: 24, xs: 24 }}
        filterFields={auditFilterFields}
        filter={filter}
        setFilter={updateFilterString}
      />

      <SCommentWrapper>
        {isFetchingAudits ? (
          //loading
          <Spin size="large" />
        ) : (
          //loaded
          audits?.length > 0 && <AuditsList audits={audits} />
        )}
      </SCommentWrapper>
    </SPageContent>
  );
};

export default AuditLog;
