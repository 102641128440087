import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Input, List, message, Spin } from "antd";

import dayjs from "dayjs";

import styled from "styled-components";
import { Comment } from "@ant-design/compatible";
import { JournalComment } from "../types";
import { useParams } from "react-router-dom";
import CommentService from "../services/comment.service";
import { useGetCurrentUser } from "../hooks/useGetCurrentUser";
import { permissionManager } from "../services/permission_manager";
import { DeletedUser } from "./shared/DeletedUser";

const { TextArea } = Input;

const SCommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    min-width: 650px;
  }
`;

const CommentList = ({ comments }: { comments: JournalComment[] }) => (
  <List
    dataSource={comments}
    header={`${comments.length} ${
      comments.length > 1 ? "comments" : "comment"
    }`}
    itemLayout="horizontal"
    renderItem={({ comment, date, user }: JournalComment) => {
      return (
        <Comment
          author={user?.email || <DeletedUser />}
          avatar={
            user?.company?.logo
              ? `${user?.company?.logo}`
              : `/club_logos/48/unknown.png`
          }
          content={comment}
          datetime={dayjs(date).format("Do MMM, YYYY, h:mm:ss a")}
        />
      );
    }}
  />
);

const Editor = ({ onChange, onSubmit, submitting, value }: any) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="button"
        loading={submitting}
        onClick={onSubmit}
        type="primary"
      >
        Add Comment
      </Button>
    </Form.Item>
  </>
);

const Comments = () => {
  const [comments, setComments] = useState<JournalComment[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [canCreateComment, setCanCreateComment] = useState(false);
  const [newComment, setNewComment] = useState({
    submitting: false,
    comment: "",
  });
  const { user } = useGetCurrentUser();

  const commentService = useMemo(() => new CommentService(), []);

  const params = useParams();
  const id = params.id;

  //get form data
  const fetchComments = async () => {
    setIsLoading(true);
    const hide = message.loading("Loading..");

    try {
      const comments = await commentService.getCommentsForTerm(id);

      setComments(comments);

      setIsLoading(false);
      hide();
    } catch (err: any) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    (async () => {
      setCanCreateComment(
        await permissionManager.hasPermission("cdbComment", "Create"),
      );
    })();
  }, [permissionManager]);

  //init form data
  useEffect(() => {
    if (id) {
      fetchComments();
    }
  }, [id]);

  const handleChange = (e: any) => {
    setNewComment({
      ...newComment,
      comment: e.target.value,
    });
  };

  const handleSubmit = async () => {
    setNewComment({
      ...newComment,
      submitting: true,
    });

    try {
      const newCommentAdded = await commentService.createComment({
        comment: newComment.comment,
        termId: id,
        userId: user.id,
      });

      if (newCommentAdded) {
        fetchComments();
        setNewComment({
          submitting: false,
          comment: "",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <SCommentWrapper>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <>
          {comments?.length > 0 && <CommentList comments={comments} />}
          {canCreateComment && (
            <Comment
              content={
                <Editor
                  onChange={handleChange}
                  onSubmit={handleSubmit}
                  submitting={newComment.submitting}
                  value={newComment.comment}
                />
              }
            />
          )}
        </>
      )}
    </SCommentWrapper>
  );
};

export default Comments;
