import { TermType } from './enums';
import { AuditType, Company, PLSeason, Term } from './types';
import dayjs from 'dayjs';

var isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

export const getLabelForTransactionType = (termType: TermType) => {
  switch (termType) {
    case TermType.threshold:
      return 'Threshold';
    case TermType.databank:
      return 'Databank';
    case TermType.associated:
      return 'Assoc. Party';
    default:
      return undefined;
  }
};

export const getLabelForAuditType = (auditType: AuditType) => {
  switch (auditType) {
    case AuditType.views:
      return 'Views';
    case AuditType.changes:
      return 'Changes';
    case AuditType.all:
      return 'All';
    default:
      return undefined;
  }
};

export const capitalizeFirstLetter = (string: string): string => {
  const capitalize = (string: string) =>
    string.charAt(0).toUpperCase() + string.slice(1);

  if (!string) {
    return '';
  }

  return string?.length > 1
    ? string
        .split(' ')
        .map((s) => capitalize(s))
        .join(' ')
        .replace(/fc/gi, 'FC')
    : string.constructor === String
    ? capitalize(string)
    : '';
};

export const hydrateCompanies = (companies: Company | Company[]) => {
  // Convert a single company into an array if needed
  let companiesArray = Array.isArray(companies) ? companies : [companies];

  let outp = [];

  for (let i = 0; i < companiesArray.length; i++) {
    let item = {
      ...companiesArray[i],
      text: companiesArray[i]?.name,
      value: companiesArray[i]?.id,
      display: companiesArray[i]?.name,
      key: i,
    };
    outp.push(item);
  }

  // if it's an array of companies we're hydrating, return the array - otherwise the single company
  return Array.isArray(companies) ? outp : outp[0];
};

export const sortDataByTermTypeEnumOrder = (
  data: { type: TermType; count: number }[],
) => {
  const enumOrder = Object.values(TermType);

  return data?.sort((a, b) => {
    const indexA = enumOrder.indexOf(a.type);
    const indexB = enumOrder.indexOf(b.type);

    return indexA - indexB;
  });
};

export const formatFees = (fee: number) => Number(fee)?.toFixed(2);

export const getSeasonLabelForDate = (referenceDate: Date) => {
  const currentYear = referenceDate.getFullYear();
  const currentMonth = referenceDate.getMonth() + 1; // Month is zero-indexed

  // Determine the start and end year of the season
  let startYear, endYear;
  if (currentMonth >= 8) {
    startYear = currentYear;
    endYear = currentYear + 1;
  } else {
    startYear = currentYear - 1;
    endYear = currentYear;
  }

  // Shorten the end year to the last two digits
  const endYearShort = endYear.toString().slice(2);

  // Construct the label based on the start and end years
  return `Premier League ${startYear}/${endYearShort}`;
};

export const checkTermHistoricalState = ({
  company,
  agreementDate,
}: {
  company: Company;
  agreementDate: Date;
}) => {
  // Define the start and end dates for the historical period
  const historicalStartDate = dayjs(new Date('2016-01-01'));
  const historicalEndDate = dayjs(new Date('2021-12-14'));

  const isHistoricalDate = () =>
    // @ts-ignore
    dayjs(agreementDate).isBetween(
      historicalStartDate,
      historicalEndDate,
      'month',
      '[]',
    ) || dayjs(agreementDate).isBefore(historicalStartDate);

  // Check if the club was not in the Premier League during the agreement date
  const notInPLDuringAgreementDate = () => {
    const agreementDateSeasonLabel = getSeasonLabelForDate(agreementDate);

    // check if the club was in PL for the season the  agreement date applies to
    return !company?.plSeasons?.some(
      (plSeason: PLSeason) => plSeason.label === agreementDateSeasonLabel,
    );
  };

  return {
    isHistoricalDate: isHistoricalDate(),
    notInPLDuringAgreementDate: notInPLDuringAgreementDate(),
  };
};

export const getDataForCsvExport = (csvData: Term[]) =>
  csvData.map((term: Term) => ({
    trackingId: `CDB${term.number}`,
    organisation: term.company?.name,
    transactionType: term.type,
    title: term.title,
    contractingParty: term.contractingParty,
    status: term.state,
    feesInKind: term.feesInKind,
    feesCash: term.feesCash,
    feesTotal: term.feesTotal,
    dateOfAgreement:
      term.agreementDate && dayjs(term.agreementDate).format('DD-MM-YYYY'),
    createdDate: term.createdAt && dayjs(term.createdAt).format('DD-MM-YYYY'),
    submittedDate:
      term.submissionDate && dayjs(term.submissionDate).format('DD-MM-YYYY'),
    convertedOn: term.convertedOn,
    furtherDetailsRequested: term.furtherDetailsRequested ? 'Yes' : 'No',
  }));

export const logoResolver = (logoUrl: string) =>
  logoUrl?.includes('https://') ? logoUrl : `/club_logos/48/${logoUrl}`;
