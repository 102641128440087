import axios from 'axios';
import { hydrateCompanies } from '../utils';
import { Company, CreateCommentDto, JournalComment } from '../types';

export default class CommentService {
  // gets comments for term
  async getCommentsForTerm(id: string) {
    try {
      const response = await axios.get(`/comment/term/${id}`);
      if (response.data && response.data.data) {
        return response.data.data.map((comment: JournalComment) => ({
          ...comment,
          user: comment.user
            ? {
                ...comment.user,
                company: hydrateCompanies(
                  comment.user.company as Company,
                ),
              }
            : undefined,
        }));
      }
      return null;
    } catch (error) {
      console.error('Error in getCommentsForTerm:', error);
      throw error;
    }
  }

  // creates draft term
  async createComment(payload: CreateCommentDto) {
    try {
      const response = await axios.post(`/comment`, payload);
      if (response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.error('Error in postComment:', error);
      throw error;
    }
  }
}
