import {
  Button,
  Card,
  Col,
  DatePicker,
  Flex,
  Form,
  List,
  Radio,
  Row,
  Space,
} from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { Line, LineConfig } from "@ant-design/charts";
import dayjs from "dayjs";
import styled from "styled-components";
import TermService from "../../../services/term.service";
import { TermType } from "../../../enums";
import { Term, TransactionsPerCLubFilter } from "../../../types";
import { ClearOutlined, ExportOutlined } from "@ant-design/icons";
import { useGetCsvData } from "../../../hooks/useGetCsvData";
import {
  capitalizeFirstLetter,
  getDataForCsvExport,
  sortDataByTermTypeEnumOrder,
} from "../../../utils";
import dayjsBusinessDays from "dayjs-business-days2";

dayjs.extend(dayjsBusinessDays);

const SRow = styled(Row)`
  margin: 20px 0;
`;

const { RangePicker } = DatePicker;

type TermCountData = { type: TermType; count: number };

type TransactionVelocityColumnData = {
  timeline: string;
  "Time to resolve": number;
  count: number;
};

export const VelocityOverview = () => {
  const [form] = Form.useForm();
  const [csvQueryParams, setCsvQueryParams] = useState("");
  const [totalTermsCount, setTotalTermsCount] = useState<TermCountData[]>();

  const termService = useMemo(() => new TermService(), []);

  const oneYearAgo = dayjs()
    .subtract(1, "year")
    .startOf("day")
    .format("YYYY/MM/DD");

  const endOfWeek = dayjs().endOf("week").format("YYYY/MM/DD");

  const emptyFilterState = {
    transactionType: "all",
    furtherDetails: "all",
    submittedToPlDate: [oneYearAgo, endOfWeek],
  } as TransactionsPerCLubFilter;

  const getCachedFilter = () => {
    const cachedFilter = localStorage.getItem("velocityGraphFilter");
    if (cachedFilter) {
      return JSON.parse(cachedFilter);
    }

    return emptyFilterState;
  };

  const [filter, setFilter] = useState<TransactionsPerCLubFilter>(
    getCachedFilter(),
  );

  const onClearFilter = () => {
    //clear filter
    localStorage.removeItem("velocityGraphFilter");

    setFilter(emptyFilterState);
  };

  const [transactionsVelocity, setTransactionsVelocity] = useState<
    TransactionVelocityColumnData[]
  >([]);

  const [fetchingTransactions, setFetchingTransactions] = useState(false);
  const [isExportingGraph, setIsExportingGraph] = useState(false);

  const { csvData } = useGetCsvData(`${csvQueryParams}`, "terms");

  const getCompletedTransactionsOverTime = async () => {
    setFetchingTransactions(true);

    const from = dayjs(filter.submittedToPlDate[0]).toISOString();
    const to = dayjs(filter.submittedToPlDate[1]).toISOString();

    // if type filter is "All", we fetch every transaction but not databank - else, just he selected type
    const typeFilter =
      filter.transactionType === "all"
        ? ` and type ne '${TermType.databank}'`
        : ` and type eq '${TermType[filter.transactionType]}'`;

    // if further details filter is "All", we fetch every transaction
    const furtherDetailsFilter =
      filter.furtherDetails === "all"
        ? ""
        : ` and furtherDetailsRequested eq ${filter.furtherDetails}`;

    const queryParams = `?$filter=(submissionDate ne null and transactionResolutionDate ne null and submissionDate ge ${from} and submissionDate le ${to})${typeFilter}${furtherDetailsFilter}`;

    // store filters in cache
    localStorage.setItem("velocityGraphFilter", JSON.stringify(filter));

    setCsvQueryParams(queryParams);

    // console.log("queryParams", queryParams);
    const terms = await termService.getTerms(queryParams);

    // set general terms count
    if (filter.transactionType === "all") {
      setTotalTermsCount([
        {
          type: TermType.threshold,
          count: terms?.data?.filter((t: Term) => t.type === TermType.threshold)
            .length,
        },
        {
          type: TermType.associated,
          count: terms?.data?.filter(
            (t: Term) => t.type === TermType.associated,
          ).length,
        },
      ]);
    } else {
      // if filtering by a specific type, only store that transactionData type
      setTotalTermsCount([
        {
          type: filter.transactionType as TermType,
          count: terms?.data?.filter(
            (t: Term) => t.type === (filter.transactionType as TermType),
          ).length,
        },
      ]);
    }

    // sort transactions chronologically
    const termsOrderedByDate = terms.data.sort((a: any, b: any) =>
      dayjs(a.submissionDate).diff(b.submissionDate),
    );

    const transactionsVelocity = termsOrderedByDate
      .map((t: Term) => {
        return {
          timeline: dayjs(t.submissionDate).format("YYYY-MM"),
          "Time to resolve": dayjs(
            dayjs(t.transactionResolutionDate).format("YYYY-MM-DD"),
          ).businessDiff(dayjs(dayjs(t.submissionDate).format("YYYY-MM-DD"))),
        };
      })
      .reduce(
        (
          acc: TransactionVelocityColumnData[],
          current: TransactionVelocityColumnData,
        ) => {
          const existingGroup = acc.find(
            (item) => item.timeline === current.timeline,
          );

          if (existingGroup) {
            existingGroup["Time to resolve"] += current["Time to resolve"];
            existingGroup.count += 1;
          } else {
            acc.push({
              timeline: current.timeline,
              "Time to resolve": current["Time to resolve"],
              count: 1,
            });
          }

          return acc;
        },
        [],
      )
      .map((group: TransactionVelocityColumnData) => {
        return {
          timeline: group.timeline,
          "Time to resolve": Math.round(group["Time to resolve"] / group.count),
        };
      });

    // console.log("transactionsVelocity", transactionsVelocity);

    setTransactionsVelocity(transactionsVelocity);
    setFetchingTransactions(false);
  };

  const onConfirmDateRange = () => {
    const dates = (
      form.getFieldValue("submittedToPl") || filter.submittedToPlDate
    ).map((date: string) => dayjs(date).format("YYYY/MM/DD"));
    setFilter({ ...filter, submittedToPlDate: dates });
  };

  const VelocityGraph = () => {
    const config: LineConfig = {
      data: transactionsVelocity,
      xField: "timeline",
      yField: "Time to resolve",
      yAxis: {
        alias: "Time to resolve in days (on avg)",
      },
      xAxis: {
        label: {
          autoHide: false,
          autoRotate: true,
          autoEllipsis: true,
        },
      },
      smooth: false,
      appendPadding: [30, 0, 0, 0],
    };

    return <Line {...config} />;
  };

  //fetch data
  useEffect(() => {
    getCompletedTransactionsOverTime();
  }, [filter]);

  const onExportGraphData = async () => {
    setIsExportingGraph(true);
    try {
      const response = await termService.exportGraph({
        odataQuery: csvQueryParams,
      });

      // Create a Blob URL from the response data
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a download link
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `Dashboard Report-${dayjs(new Date()).format("DD-MM-YYYY LT")}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      setIsExportingGraph(false);
    } catch (error) {
      console.error(error);
      setIsExportingGraph(false);
    }
  };

  return (
    <Form form={form}>
      <SRow>
        <Card
          id="velocityGraph"
          actions={[
            <Button
              icon={<ExportOutlined />}
              loading={isExportingGraph}
              onClick={onExportGraphData}
            >
              Export
            </Button>,
          ]}
          size="small"
          title="Average Resolution Time (in days)"
          style={{ flex: "auto" }}
        >
          {/*date range filter*/}
          <SRow justify={"start"} gutter={48}>
            <Col lg={24} md={24} sm={24} xs={24}>
              {filter?.submittedToPlDate && (
                <Flex gap={"middle"}>
                  <Form.Item label="Submitted to PL" name={"submittedToPl"}>
                    <RangePicker
                      value={[
                        dayjs(filter?.submittedToPlDate[0], "YYYY-MM-DD"),
                        dayjs(filter?.submittedToPlDate[1], "YYYY-MM-DD"),
                      ]}
                      defaultValue={[
                        dayjs(filter?.submittedToPlDate[0], "YYYY-MM-DD"),
                        dayjs(filter?.submittedToPlDate[1], "YYYY-MM-DD"),
                      ]}
                    />
                  </Form.Item>
                  <Button type="primary" onClick={onConfirmDateRange}>
                    Apply date range
                  </Button>
                </Flex>
              )}
            </Col>
          </SRow>
          <SRow justify={"start"} gutter={48}>
            {/*type filter*/}
            <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: 8 }}>
              <Form.Item label="Type">
                <Radio.Group
                  onChange={(e: any) =>
                    setFilter({
                      ...filter,
                      transactionType: e.target.value as TermType & "all",
                    })
                  }
                  value={filter.transactionType}
                >
                  <Radio.Button value="all">All</Radio.Button>
                  <Radio.Button value={TermType.threshold}>
                    Threshold
                  </Radio.Button>
                  <Radio.Button value={TermType.associated}>
                    Associated Party
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
            {/*further details filter*/}
            <Col lg={12} md={24} sm={24} xs={24} style={{ marginBottom: 8 }}>
              <Form.Item label="Further Details">
                <Radio.Group
                  onChange={(e: any) =>
                    setFilter({
                      ...filter,
                      furtherDetails: e.target.value as boolean & "all",
                    })
                  }
                  value={filter.furtherDetails}
                >
                  <Radio.Button value="all">All</Radio.Button>
                  <Radio.Button value={true}>Requested</Radio.Button>
                  <Radio.Button value={false}>Not Requested</Radio.Button>
                </Radio.Group>
              </Form.Item>
            </Col>
          </SRow>
          {/*clear filter button*/}
          <SRow>
            <Col span={24} style={{ textAlign: "right" }}>
              <Space>
                {filter && Object.keys(filter).length > 0 && (
                  <Button
                    key={1}
                    onClick={onClearFilter}
                    icon={<ClearOutlined />}
                  >
                    Clear
                  </Button>
                )}
              </Space>
            </Col>
          </SRow>

          <Card>
            <Row>
              <Col span={24}>
                <List
                  grid={{ gutter: 16, column: 4 }}
                  dataSource={sortDataByTermTypeEnumOrder(totalTermsCount)}
                  renderItem={(item, index) => (
                    <List.Item key={index}>
                      <Card
                        size={"small"}
                        title={capitalizeFirstLetter(item?.type)}
                      >
                        {item?.count}{" "}
                      </Card>
                    </List.Item>
                  )}
                />
              </Col>
            </Row>

            <VelocityGraph />
          </Card>
        </Card>
      </SRow>
    </Form>
  );
};
