import React, { useState } from "react";

import { Button, message, Modal, Steps, Typography, Upload } from "antd";
import styled from "styled-components";

import {
  CheckCircleOutlined,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import env from "@beam-australia/react-env";
import { useAuth } from "oidc-react";

const REACT_APP_API_URL = env("API_URL");

const { Step } = Steps;
const { Paragraph } = Typography;

const SDownloadTemplateWrap = styled.div`
  margin-bottom: 20px;
`;

const ImportDataModal = ({ isModalVisible, setIsModalVisible }: any) => {
  const [loading, setLoading] = useState(false);
  const auth = useAuth();

  const [dwnBtnActive, setDwnBtnActive] = useState(false);
  const [uplBtnActive, setUplBtnActive] = useState(false);
  const [stepsProgress, setStepsProgress] = useState(0);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const dwnBtnClick = () => {
    setDwnBtnActive(true);
    if (stepsProgress === 0) setStepsProgress(1);
  };

  const uplBtnClick = () => {
    setDwnBtnActive(true);
    setUplBtnActive(true);
    setStepsProgress(2);
  };

  const uploadProps = {
    name: "file",
    action: `${REACT_APP_API_URL}/terms/import`,
    headers: {
      Authorization: `Bearer ${auth.userData.access_token}`,
    },
    defaultFileList: [] as any[],
    multiple: false,
    beforeUpload: (file: any) => {
      setLoading(true);
      const isExcel =
        file.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      if (!isExcel) {
        message.error(`${file.name} is not a .xlsx file`);
        return Upload.LIST_IGNORE;
      }
      return true;
    },
    onChange(info: any) {
      setLoading(true);
      if (info.file.status === "done") {
        message.success(
          `${info.file.name} import in progress, please refresh the list.`,
        );
        setLoading(false);

        setIsModalVisible(false);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} import failed.`);
      }
    },
  };

  return (
    <Modal
      title={`Import Data`}
      width={750}
      open={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
      ]}
    >
      <Paragraph>
        To import legacy transaction deals to the Commercial Databank, please
        follow the below process. Records imported this way will be tracked as
        'Historical Transactions' and will not require review.
      </Paragraph>
      <SDownloadTemplateWrap>
        <form method="get" action="template/CDB_Template.xlsx">
          <Button
            icon={dwnBtnActive ? <CheckCircleOutlined /> : <DownloadOutlined />}
            onClick={() => dwnBtnClick()}
            ghost={dwnBtnActive}
            type={dwnBtnActive ? "primary" : "default"}
            htmlType="submit"
          >
            Download Template
          </Button>
        </form>
      </SDownloadTemplateWrap>

      <Steps direction="vertical" size="small" current={stepsProgress}>
        <Step
          title="Template"
          description="Download the Excel template above."
        />
        <Step title="Populate" description="Fill in each row." />
        <Step
          title="Reupload"
          description="Upload the populated excel sheet below and submit."
        />
      </Steps>
      <Upload {...uploadProps}>
        <Button
          icon={uplBtnActive ? <CheckCircleOutlined /> : <UploadOutlined />}
          onClick={() => uplBtnClick()}
          ghost={uplBtnActive}
          type={uplBtnActive ? "primary" : "default"}
          // loading={loading}
        >
          Upload Template
        </Button>
      </Upload>
    </Modal>
  );
};

export default ImportDataModal;
