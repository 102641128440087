import axios from 'axios';
import {hydrateCompanies} from '../utils';
import {Company, CreateDraftPlayerTransactionDto, PlayerTransaction, Term,} from '../types';

export default class PlayerTransactionService {
  // gets terms, with filters and pagination
  async getPlayerTransactions(queryParams: string = '') {
    try {
      const response = await axios.get(`/player-transactions${queryParams}`);
      if (response.data) {
        return {
          ...response.data,
        };
      }
      return null;
    } catch (error) {
      console.error('Error in getPlayerTransactions:', error);
      throw error;
    }
  }

  // gets single term
  async getPlayerTransaction(id: string, odataReq?: string) {
    try {
      const response = await axios.get(
        `/player-transactions/${id}${odataReq || ''}`,
      );
      if (response.data) {
        return {
          ...response.data,
          company: hydrateCompanies(response.data.company as Company),
        };
      }
      return null;
    } catch (error) {
      console.error('Error in getPlayerTransaction', error);
      throw error;
    }
  }

  // creates draft term
  async createPlayerTransaction(payload: CreateDraftPlayerTransactionDto) {
    try {
      const response = await axios.post(`/player-transactions`, payload);
      if (response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.error('Error in postPlayerTransaction:', error);
      throw error;
    }
  }

  // updates term
  async updatePlayerTransaction(payload: Partial<Term>) {
    try {
      const response = await axios.put(
        `/player-transactions/${payload.id}`,
        payload,
      );
      if (response.data) {
        return {
          ...response.data,
        };
      }
      return null;
    } catch (error) {
      console.error('Error in putPlayerTransaction:', error);
      throw error;
    }
  }

  // deletes term
  async deletePlayerTransaction(payload: { id: string; userRole: string }) {
    const { id, userRole } = payload;

    try {
      const response = await axios.post(`/player-transactions/delete/${id}`, {
        userRole,
      });

      if (response.data) {
        return {
          ...response.data,
        };
      }
    } catch (error) {
      console.error('Error in deletePlayerTransactions:', error);
      throw error;
    }
  }

  // exports term to pdf
  async exportPlayerTransactionToPdf(payload: Partial<PlayerTransaction>) {
    return await axios.post(`/player-transactions/export/pdf`, payload, {
      responseType: 'arraybuffer',
    });
  }

  // updates term
  // async updatePlayerTransactionSharedWith(payload: Pick<PlayerTransaction>) {
  //   const { id, sharedWith } = payload;
  //   try {
  //     const response = await axios.put(`/player-transactions/${id}/sharedWith`, sharedWith);
  //     if (response.data) {
  //       return {
  //         ...response.data,
  //       };
  //     }
  //     return null;
  //   } catch (error) {
  //     console.error('Error in sharePlayerTransaction:', error);
  //     throw error;
  //   }
  // }

  // updates term
  // async shareAllPlayerTransactionsWithUser(payload: { companyId: string; userId: string }) {
  //   try {
  //     const response = await axios.post(`/player-transactions/shareAllTerms`, payload);
  //     if (response.data) {
  //       return {
  //         ...response.data,
  //       };
  //     }
  //     return null;
  //   } catch (error) {
  //     console.error('Error in shareAllPlayerTransactions:', error);
  //     throw error;
  //   }
  // }

  // share term link with external user
  // async shareLink(payload: { playerTransactionId: string; emailAddresses: string[] }) {
  //   return await axios.post(`/player-transactions/shareLink`, payload);
  // }
}
