import React from "react";
import { Space } from "antd";

import styled from "styled-components";

const SWrapper = styled.div`
  line-height: 1;
`;

const SContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
`;

const SLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const STitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 16px;
`;

const SSpace = styled(Space)`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export default function TableToolbar({ title, buttons }: any) {
  return (
    <SWrapper>
      <SContainer>
        <SLeft>
          <STitle>{title}</STitle>
        </SLeft>

        <SSpace>{buttons}</SSpace>
      </SContainer>
    </SWrapper>
  );
}
