import { useEffect, useMemo, useRef, useState } from 'react';
import { PlayerTransaction, Term } from '../types';
import TermService from '../services/term.service';
import { hydrateCompanies } from '../utils';
import PlayerTransactionService from '../services/player-transaction.service';

export const useGetCsvData = (
  initialQueryParams: string = '',
  dataSource: 'terms' | 'player-transactions',
) => {
  const [csvData, setCsvData] = useState<(Term | PlayerTransaction)[]>([]);
  const [csvDataCount, setCsvDataCount] = useState<number>();
  const [isFetchingCsvData, setIsFetchingCsvData] = useState<boolean>(false);
  const queryParamsRef = useRef<string>(initialQueryParams);

  const termService = useMemo(() => new TermService(), []);
  const playerTransactionService = useMemo(
    () => new PlayerTransactionService(),
    [],
  );

  const fetchData = async () => {
    setIsFetchingCsvData(true);

    const getter =
      dataSource === 'terms'
        ? termService.getTerms
        : playerTransactionService.getPlayerTransactions;

    const csvData = await getter(queryParamsRef.current);

    if (csvData.data) {
      const termsCompaniesHydrated = csvData.data?.map((term: Term) => ({
        ...term,
        company: hydrateCompanies(term.company),
      }));

      setCsvData(termsCompaniesHydrated);
      setCsvDataCount(csvData.count);
      setIsFetchingCsvData(false);
    }
  };

  useEffect(() => {
    queryParamsRef.current = initialQueryParams;
    fetchData();
  }, [initialQueryParams]);

  const refetchCsvData = () => {
    fetchData();
  };

  return { csvData, refetchCsvData, csvDataCount, isFetchingCsvData };
};
