import axios from 'axios';
import { hydrateCompanies } from '../utils';
import { Company, CreateDraftTermDto, Term } from '../types';

export default class TermService {
  // gets terms, with filters and pagination
  async getTerms(queryParams: string = '') {
    try {
      const response = await axios.get(`/terms${queryParams}`);
      if (response.data) {
        return {
          ...response.data,
        };
      }
      return null;
    } catch (error) {
      console.error('Error in getTerms:', error);
      throw error;
    }
  }

  // gets single term
  async getTerm(id: string, odataReq?: string) {
    try {
      const response = await axios.get(`/terms/${id}${odataReq || ''}`);
      if (response.data) {
        return {
          ...response.data,
          company: hydrateCompanies(response.data.company as Company),
        };
      }
      return null;
    } catch (error) {
      console.error('Error in getTerm:', error);
      throw error;
    }
  }

  // creates draft term
  async createTerm(payload: CreateDraftTermDto) {
    try {
      const response = await axios.post(`/terms`, payload);
      if (response.data) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.error('Error in postTerm:', error);
      throw error;
    }
  }

  // updates term
  async updateTerm(payload: Partial<Term>) {
    try {
      const response = await axios.put(`/terms/${payload.id}`, payload);
      if (response.data) {
        return {
          ...response.data,
        };
      }
      return null;
    } catch (error) {
      console.error('Error in putTerm:', error);
      throw error;
    }
  }

  // deletes term
  async deleteTerm(payload: { id: string; userRole: string }) {
    const { id, userRole } = payload;

    try {
      const response = await axios.post(`/terms/delete/${id}`, { userRole });

      if (response.data) {
        return {
          ...response.data,
        };
      }
    } catch (error) {
      console.error('Error in deleteTerm:', error);
      throw error;
    }
  }

  // exports term to pdf
  async exportTermToPdf(payload: Partial<Term>) {
    return await axios.post(`/terms/export/pdf`, payload, {
      responseType: 'arraybuffer',
    });
  }

  // exports graph and graph data to zip
  async exportGraph(payload: { odataQuery: string }) {
    const { odataQuery } = payload;
    const url = `/terms/export/graph${odataQuery}`;
    return await axios.get(url, {
      responseType: 'blob',
    });
  }

  // updates term
  async updateTermSharedWith(payload: Pick<Term, 'sharedWith' | 'id'>) {
    const { id, sharedWith } = payload;
    try {
      const response = await axios.put(`/terms/${id}/sharedWith`, sharedWith);
      if (response.data) {
        return {
          ...response.data,
        };
      }
      return null;
    } catch (error) {
      console.error('Error in shareTerm:', error);
      throw error;
    }
  }

  // updates term
  async shareAllTermsWithUser(payload: { companyId: string; userId: string }) {
    try {
      const response = await axios.post(`/terms/shareAllTerms`, payload);
      if (response.data) {
        return {
          ...response.data,
        };
      }
      return null;
    } catch (error) {
      console.error('Error in shareAllTerms:', error);
      throw error;
    }
  }

  // share term link with external user
  async shareLink(payload: { termId: string; emailAddresses: string[] }) {
    return await axios.post(`/terms/shareLink`, payload);
  }
}
