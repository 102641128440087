import React, { useEffect, useMemo, useState } from "react";
import { Col, UploadFile, UploadProps } from "antd";
import {
  Button,
  Divider,
  Form,
  message,
  Popconfirm,
  Spin,
  Tabs,
  Upload,
} from "antd";
import {
  DeleteOutlined,
  InboxOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import AuditLog from "../components/AuditLog";

import Comments from "../components/Comments";

import DraggerFileLoaderModal from "./modals/DraggerFileLoaderModal";
import TermFormFields from "./enums/TermFormFields";

import styled from "styled-components";
import {
  AttachmentFile,
  BespokeAttachments,
  Company,
  TermFormField,
  Term,
} from "../types";
import { AttachmentType, UserRole } from "../enums";
import { UploadChangeParam } from "antd/es/upload/interface";
import AttachmentService from "../services/attachment.service";
import { useGetCurrentUser } from "../hooks/useGetCurrentUser";
import { useAuth } from "oidc-react";
import Loading from "@ant-design/pro-card/lib/components/Loading";
import { useParams } from "react-router-dom";
import { useGetAttachments } from "../hooks/useGetAttachments";

type HandleChangeWithAttachmentType = (
  info: UploadChangeParam<UploadFile<AttachmentFile>>,
  type: AttachmentType,
) => void;

const { Dragger } = Upload;

const SFormWrapper = styled.div`
  max-width: 900px;
  position: relative;
  margin: 20px auto auto auto;
`;

const SBespokeAttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-bottom: 10px;
  > span {
    flex: 1;
  }
`;

const SBespokeAttachment = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  width: 0;
  justify-content: flex-start;
`;

import env from "@beam-australia/react-env";
import { permissionManager } from "../services/permission_manager";

const REACT_APP_API_URL = env("API_URL");

const PartnershipForm = ({
  newRecord,
  company,
  term,
  userRole,
  companies,
  onFinish,
  emitBespokeAttachments,
  form,
  canWrite,
  canCopy,
  isThreshold,
  isAssociatedParty,
  scrollToFirstError,
  onAttachmentsChanged,
}: {
  newRecord: boolean;
  company?: Company;
  term?: Term;
  userRole: UserRole;
  companies?: Company[];
  onFinish: (fieldValues: TermFormField[]) => void;
  emitBespokeAttachments?: (bespokenAttachments: BespokeAttachments) => void;
  form: any;
  canWrite: boolean;
  canCopy: boolean;
  isThreshold?: boolean;
  isAssociatedParty?: boolean;
  scrollToFirstError?: boolean;
  onAttachmentsChanged?: () => void;
}) => {
  const params = useParams();
  const id = params.id;

  const [fileModalVisible, setFileModalVisible] = useState(false);
  const attachmentService = useMemo(() => new AttachmentService(), []);
  const { user } = useGetCurrentUser();
  const auth = useAuth();

  const [furtherDetailsRequestedValue, setFurtherDetailsRequestedValue] =
    useState<boolean>();

  const [canReadAudits, setCanReadAudits] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deletingFile, setDeletingFile] = useState<AttachmentFile>(
    {} as AttachmentFile,
  );
  const [fileModalFile, setFileModalFile] = useState({} as AttachmentFile);
  const [defaultFileList, setDefaultFileList] = useState(
    [] as AttachmentFile[],
  );
  const [redactedFileList, setRedactedFileList] = useState(
    [] as AttachmentFile[],
  );
  const [aptDeclarationsFileList, setAptDeclarationsFileList] = useState(
    [] as AttachmentFile[],
  );
  const [unredactedFileList, setUnredactedFileList] = useState(
    [] as AttachmentFile[],
  );
  const [plReportsFileList, setPlReportsFileList] = useState(
    [] as AttachmentFile[],
  );
  const [attachmentList, setAttachmentList] = useState<AttachmentFile[]>([]);

  const [bespokeAttachments, setBespokeAttachments] =
    useState<BespokeAttachments>({
      unredacted_agreement: [],
      redacted_agreement: [],
      apt_declarations: [],
      pl_reports: [],
    });

  const { attachments, attachmentsCount, refetchAttachments } =
    useGetAttachments(id);

  useEffect(() => {
    // removed "&& attachments.length > 0" from the condition below because it wouldn't refresh the attachments list
    // after removing the last attachment - need to check if it breaks something else
    if (attachments) {
      buildAttachmentsList();
    }
  }, [attachments, attachmentsCount]);

  //fetch attachments
  const buildAttachmentsList = async () => {
    if (attachments) {
      let generalOutp: any = [];
      let redactedAtt: any = [];
      let unredactedAtt: any = [];
      let aptDeclarationsAtt: any = [];
      let plReportAtt: any = [];

      attachments.forEach((attachment: any) => {
        let att = {
          uid: attachment.id,
          name: attachment.fileName,
          status: "done",
          response: "200",
          url: attachment.url,
        };

        if (
          attachment.type &&
          attachment.type === AttachmentType.redacted_agreement
        ) {
          // console.log("redacted_agreement");
          redactedAtt.push(att);
        } else if (
          attachment.type &&
          attachment.type === AttachmentType.unredacted_agreement
        ) {
          // console.log("unredacted_agreement");
          unredactedAtt.push(att);
        } else if (
          attachment.type &&
          attachment.type === AttachmentType.pl_reports
        ) {
          // console.log("pl_reports");
          plReportAtt.push(att);
        } else if (
          attachment.type &&
          attachment.type === AttachmentType.apt_declarations
        ) {
          // console.log("apt_declarations");
          aptDeclarationsAtt.push(att);
        } else {
          // console.log("general");
          generalOutp.push(att);
        }
      });

      setAttachmentList(generalOutp);

      const bespokeAttachmentCounts = {
        unredacted_agreement: unredactedAtt,
        redacted_agreement: redactedAtt,
        apt_declarations: aptDeclarationsAtt,
        pl_reports: plReportAtt,
      };

      setBespokeAttachments(bespokeAttachmentCounts);

      setDefaultFileList(generalOutp);
      setRedactedFileList(redactedAtt);
      setUnredactedFileList(unredactedAtt);
      setAptDeclarationsFileList(aptDeclarationsAtt);
      setPlReportsFileList(plReportAtt);

      // bespoke attachments are needed in Form parent comp
      emitBespokeAttachments(bespokeAttachmentCounts);
    }
  };

  const isAdminUser = userRole === UserRole.admin;
  const isLegalUser = userRole === UserRole.legal;
  const isClubUser = userRole === UserRole.club;

  useEffect(() => {
    (async () => {
      setCanReadAudits(
        await permissionManager.hasPermission("cdbAudit", "Read"),
      );
    })();
  }, [permissionManager]);

  const loadFileModal = (file: any) => {
    setFileModalFile(file);
    setFileModalVisible(true);
  };

  const closeFileModal = () => {
    setFileModalFile({} as AttachmentFile);
    setFileModalVisible(false);
  };

  const handleFileChange: HandleChangeWithAttachmentType = async (
    info: UploadChangeParam<UploadFile<AttachmentFile>>,
    type: AttachmentType,
  ) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} file uploaded successfully`);

      if (info.file.response) {
        if (info.file.response.url) info.file.url = info.file.response.url;
        if (info.file.response.name) info.file.name = info.file.response.name;
        if (info.file.response.id) info.file.uid = info.file.response.id;
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }

    if (type === AttachmentType.redacted_agreement) {
      setBespokeAttachments({
        ...bespokeAttachments,
        redacted_agreement: [...(info.fileList as unknown as AttachmentFile[])],
      });

      let newFileList = [...info.fileList];
      setRedactedFileList(newFileList as unknown as AttachmentFile[]);
    } else if (type === AttachmentType.unredacted_agreement) {
      setBespokeAttachments({
        ...bespokeAttachments,
        unredacted_agreement: [...info.fileList],
      } as unknown as BespokeAttachments);

      let newFileList = [...info.fileList];
      setUnredactedFileList(newFileList as unknown as AttachmentFile[]);
    } else if (type === AttachmentType.apt_declarations) {
      setBespokeAttachments({
        ...bespokeAttachments,
        apt_declarations: [...info.fileList],
      } as unknown as BespokeAttachments);

      let newFileList = [...info.fileList];
      setAptDeclarationsFileList(newFileList as unknown as AttachmentFile[]);
    } else if (type === AttachmentType.pl_reports) {
      setBespokeAttachments({
        ...bespokeAttachments,
        pl_reports: [...info.fileList],
      } as unknown as BespokeAttachments);

      let newFileList = [...info.fileList];

      setPlReportsFileList(newFileList as unknown as AttachmentFile[]);
    } else if (type === AttachmentType.default) {
      let newFileList = [...info.fileList];
      setDefaultFileList(newFileList as unknown as AttachmentFile[]);
      setAttachmentList(newFileList as unknown as AttachmentFile[]);
    } else {
      throw Error(`Attachment type not recognised, ${type}`);
    }
    onAttachmentsChanged();
    emitBespokeAttachments(bespokeAttachments);
  };

  const onDeleteAttachment = async () => {
    // just a sanity check, should not be needed as
    // the delete button is not visible if !isAdminUser
    if (!isAdminUser) {
      return;
    }

    setIsLoading(true);

    if (deletingFile) {
      try {
        const deleteRequest = await attachmentService.deleteAttachment({
          fileId: deletingFile.uid,
        });

        if (deleteRequest.status === 201) {
          message.success(`${deletingFile.name} file deleted successfully`);

          refetchAttachments();
          onAttachmentsChanged();
        }
      } catch (err) {
        console.log(err);
      }
      setIsLoading(false);
    }
  };

  const baseUploadProps = {
    name: "file",
    onPreview: (file: UploadFile<AttachmentFile>) => {
      setFileModalVisible(true);
      setFileModalFile(file as unknown as AttachmentFile);
    },
    action: `${REACT_APP_API_URL}/attachments/upload`,
    headers: {
      Authorization: `Bearer ${auth.userData.access_token}`,
    },
    maxCount: 10,
    disabled: !canWrite || isLoading,
    multiple: true,
    showUploadList: isLoading
      ? false
      : {
          showRemoveIcon: !!isAdminUser,
          removeIcon: (
            <Popconfirm
              title="Are you sure you would like to delete this attachment?"
              key="delete-attachment"
              icon={<DeleteOutlined />}
              onConfirm={onDeleteAttachment}
            >
              <DeleteOutlined />
            </Popconfirm>
          ),
        },
    async onRemove(file: UploadFile<AttachmentFile>) {
      setDeletingFile(file as unknown as AttachmentFile);
      // prevent default, because we have to show the confirmation dialogue
      return false;
    },
    beforeUpload: (file: any) => {
      let upload = true;
      if (file.size > 50000000) {
        message.error(`Attachment size exceeds the allowable limit of 50MB.`);
        upload = false;
      }

      const mimeBlockList = [
        "application/bat",
        "application/octet-stream",
        "application/postscript",
        "application/x-bat",
        "magnus-internal/cgi",
      ];
      if (mimeBlockList.includes(file.type)) {
        message.error(`Uploads of file type ${file.type} are not supported.`);
        upload = false;
      }

      return upload ? true : Upload.LIST_IGNORE;
    },
  };

  const defaultFilesUploadProps: UploadProps = {
    ...baseUploadProps,
    fileList: defaultFileList,
    onChange: (info: UploadChangeParam) =>
      handleFileChange(info, AttachmentType.default),
    data: {
      termId: term?.id,
      companyId: company?.id,
      newRecord,
      userId: user?.id,
      userRole: user?.role,
      userCompany: user?.company?.id,
      type: AttachmentType.default,
    },
  };

  const unredactedFilesProps = {
    ...baseUploadProps,
    fileList: unredactedFileList,
    onChange: (info: UploadChangeParam) =>
      handleFileChange(info, AttachmentType.unredacted_agreement),
    multiple: false,
    data: {
      termId: term?.id,
      companyId: company?.id,
      newRecord,
      userId: user?.id,
      userRole: user?.role,
      userCompany: user?.company?.id,
      type: AttachmentType.unredacted_agreement,
    },
  };

  const redactedFilesProps = {
    ...baseUploadProps,
    fileList: redactedFileList,
    onChange: (info: UploadChangeParam) =>
      handleFileChange(info, AttachmentType.redacted_agreement),
    multiple: false,
    data: {
      termId: term?.id,
      companyId: company?.id,
      newRecord,
      userId: user?.id,
      userRole: user?.role,
      userCompany: user?.company?.id,
      type: AttachmentType.redacted_agreement,
    },
  };

  const aptDeclarationsFilesProps = {
    ...baseUploadProps,
    fileList: aptDeclarationsFileList,
    onChange: (info: UploadChangeParam) =>
      handleFileChange(info, AttachmentType.apt_declarations),
    multiple: false,
    data: {
      termId: term?.id,
      companyId: company?.id,
      newRecord,
      userId: user?.id,
      userRole: user?.role,
      userCompany: user?.company?.id,
      type: AttachmentType.apt_declarations,
    },
  };

  const plReportsUploadProps = {
    ...baseUploadProps,
    fileList: plReportsFileList,
    onChange: (info: UploadChangeParam) =>
      handleFileChange(info, AttachmentType.pl_reports),
    data: {
      termId: term?.id,
      companyId: company?.id,
      newRecord: newRecord,
      userId: user?.id,
      userRole: user?.role,
      userCompany: user?.company?.id,
      type: AttachmentType.pl_reports,
    },
  };

  useEffect(() => {
    if (term) {
      setFurtherDetailsRequestedValue(term.furtherDetailsRequested);
    }
  }, [term]);

  if (!company || !user) {
    return <Loading />;
  }

  return (
    <>
      <Form
        form={form}
        name="term"
        onFinish={onFinish}
        scrollToFirstError={scrollToFirstError}
        colon={false}
        layout="vertical"
      >
        <Tabs
          defaultActiveKey="1"
          type="card"
          centered
          items={[
            {
              key: "details",
              label: "Details",
              children: (
                <SFormWrapper>
                  <TermFormFields
                    canWrite={canWrite}
                    canCopy={canCopy}
                    index={0}
                  />
                </SFormWrapper>
              ),
            },
            {
              key: "summary",
              label: "Summary",
              children: (
                <SFormWrapper>
                  <TermFormFields
                    termType={term.type}
                    canWrite={canWrite}
                    canCopy={canCopy}
                    index={1}
                  />
                </SFormWrapper>
              ),
            },
            {
              key: "fees",
              label: "Fees",
              children: (
                <SFormWrapper>
                  <TermFormFields
                    canWrite={canWrite}
                    canCopy={canCopy}
                    index={2}
                  />
                </SFormWrapper>
              ),
            },
            {
              key: "assets and partner rights",
              label: "Assets and Partner Rights",
              children: (
                <SFormWrapper>
                  <TermFormFields
                    canWrite={canWrite}
                    canCopy={canCopy}
                    index={3}
                  />
                </SFormWrapper>
              ),
            },
            isThreshold && {
              key: "arms length confirmation",
              label: "Arm's Length Confirmation",
              children: (
                <SFormWrapper>
                  <TermFormFields
                    canWrite={canWrite}
                    canCopy={canCopy}
                    index={4}
                  />
                </SFormWrapper>
              ),
            },
            isAssociatedParty && {
              key: "fair market value assessment",
              label: "Fair Market Value Assessment",
              children: (
                <SFormWrapper>
                  <TermFormFields
                    canWrite={canWrite}
                    canCopy={canCopy}
                    index={5}
                  />
                </SFormWrapper>
              ),
            },
            {
              key: "attachments",
              label: "Attachments",
              children: (
                <>
                  {isAssociatedParty && (
                    <>
                      <SBespokeAttachmentsWrapper>
                        {/* Associated Party Declaration as to Fair Market Value*/}
                        <SBespokeAttachment>
                          <div style={{ height: "230px" }}>
                            <h2>
                              Associated Party Declaration as to Fair Market
                              Value
                            </h2>
                            <p className="ant-upload-text">
                              Unless directly included within the agreement (and
                              confirmed as such to the League), please provide a
                              declaration by a director (or equivalent) of the
                              Associated Party that they consider the
                              Transaction to be at Fair Market Value (in
                              accordance with Rule E.56)
                            </p>
                          </div>
                          {isLoading ? (
                            <Spin size="small" />
                          ) : (
                            <Upload
                              listType="picture"
                              {...aptDeclarationsFilesProps}
                            >
                              <Button icon={<UploadOutlined />}>
                                Click to Upload
                              </Button>
                            </Upload>
                          )}
                        </SBespokeAttachment>

                        {/* unredacted*/}
                        <SBespokeAttachment>
                          <div style={{ height: "230px" }}>
                            <h2>Unredacted agreement</h2>
                            <p className="ant-upload-text">
                              Please upload the documentation to which this
                              Associated Party Transaction relates with no
                              redactions applied to information relevant to the
                              Transaction.
                            </p>
                          </div>
                          {isLoading ? (
                            <Spin size="small" />
                          ) : (
                            <Upload
                              listType="picture"
                              {...unredactedFilesProps}
                            >
                              <Button icon={<UploadOutlined />}>
                                Click to Upload
                              </Button>
                            </Upload>
                          )}
                        </SBespokeAttachment>

                        {/* redacted*/}
                        <SBespokeAttachment>
                          <div style={{ height: "230px" }}>
                            <h2>Redacted agreement</h2>
                            <p
                              className="ant-upload-text"
                              style={{ marginBottom: "1em" }}
                            >
                              Please upload the documentation to which this
                              Associated Party Transaction relates with all
                              references to any specific fees or other
                              consideration payable to or by the Club, Player,
                              Manager or Senior Official redacted, in accordance
                              with the applicable Fair Market Value Assessment
                              Protocol.
                            </p>
                          </div>

                          {isLoading ? (
                            <Spin size="small" />
                          ) : (
                            <Upload listType="picture" {...redactedFilesProps}>
                              <Button icon={<UploadOutlined />}>
                                Click to Upload
                              </Button>
                            </Upload>
                          )}
                        </SBespokeAttachment>
                      </SBespokeAttachmentsWrapper>
                      <Divider />
                    </>
                  )}

                  <Dragger listType="picture" {...defaultFilesUploadProps}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>

                    <p className="ant-upload-text">Attachments</p>

                    <p className="ant-upload-hint">
                      {isAssociatedParty
                        ? "Please upload any additional information you consider may be relevant to the Board’s Fair Market Value Assessment of the Associated Party Transaction. "
                        : "Click or drag files to this area to upload."}
                    </p>
                    {isLoading && <Spin size="small" />}
                  </Dragger>
                </>
              ),
            },
            !newRecord && {
              key: "journal",
              label: "Journal",
              children: <Comments />,
            },
            canReadAudits &&
              !newRecord && {
                key: "audit",
                label: "Audit",
                children: <AuditLog />,
              },
            isAdminUser && {
              key: "premier league reports",
              label: "Premier League Reports",
              children: (
                <div>
                  <Col lg={24} md={24} sm={24} xs={24}>
                    <SFormWrapper>
                      <TermFormFields
                        furtherDetailsRequestedValue={
                          furtherDetailsRequestedValue
                        }
                        canWrite={canWrite}
                        canCopy={canCopy}
                        index={6}
                      />
                    </SFormWrapper>
                  </Col>

                  <Dragger listType="picture" {...plReportsUploadProps}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>

                    <p className="ant-upload-text">Premier League Reports</p>

                    <p className="ant-upload-hint">
                      Click or drag files to this area to upload.
                    </p>
                    {isLoading && <Spin size="small" />}
                  </Dragger>
                </div>
              ),
            },
          ]}
        />
      </Form>
      <DraggerFileLoaderModal
        isModalVisible={fileModalVisible}
        closeFileModal={closeFileModal}
        file={fileModalFile}
      />
    </>
  );
};

export default PartnershipForm;
