import axios from 'axios';
import { CreateUserDto } from '../types';

export default class UserService {
  getCurrentUser = async () => {
    try {
      const response = await axios.get(`/users/current`);
      if (response.data) {
        return {
          ...response.data,
        };
      }
      return null;
    } catch (error) {
      console.error('Error in getCurrentUser:', error);
      throw error;
    }
  };

  getUsers = async (queryParams: string = '') => {
    try {
      const response = await axios.get(`/users${queryParams}`);
      if (response.data) {
        return {
          ...response.data,
        };
      }
      return null;
    } catch (error) {
      console.error('Error in getUsers:', error);
      throw error;
    }
  };

  deleteUser = async (payload: {
    id: string;
    email: string;
    userRole: string;
  }) => {
    const { id, userRole, email } = payload;

    try {
      const response = await axios.post(`/users/delete/${id}`, {
        userRole,
        email,
      });
      if (response.data) {
        return {
          ...response.data,
        };
      }
      return null;
    } catch (error) {
      console.error('Error in deleteUser:', error);
      throw error;
    }
  };

  getUser = async (id: string) => {
    try {
      const response = await axios.get(`/users/${id}`);
      if (response.data) {
        return {
          ...response.data,
        };
      }
      return null;
    } catch (error) {
      console.error('Error in getUser:', error);
      throw error;
    }
  };

  createUser = async (payload: CreateUserDto) => {
    return await axios.post(`/users/invite`, payload).then(async (res) => {
      if (res.data) {
        return {
          ...res.data,
        };
      }
    });
  };
}
