import {
  IDynamicResolver,
  IHydrateArgs,
  IHydrateResult,
  PermissionManager as PermissionManagerPL,
} from '@pl/permission-manager';
import { localStorageService } from './storage';
import { config } from '../config';
import { HttpController } from './http';
import axios from 'axios';

//TODO IUser interface
export interface IUser {
  id: string;
  name: string;
  userAvatar?: string;
  plas: IUserPLAS | undefined;
}

interface IUserPLAS {
  access_token: string;
}

export type IPermissionAction = 'Read' | 'Write' | 'Create' | 'Delete';

class PermissionManager {
  private _subjectBase: string = config.plac.application.replace(/\s/g, '');
  private _permissionManager: PermissionManagerPL;
  private _keyRules: string = `cdb:rules`;
  private _keyExpiry: string = `cdb:expiry`;
  private _keyUser: string = `cdb:user`;
  private _keySubjectNames: string = `cdb:subjectnames`;
  private _keyUserPLAS: string = `oidc.user:${config.oidc.authority}:${config.oidc.clientId}`;
  private _permissionTTL: number = 60000;
  private _dbEngineName: string = 'prisma';

  private _user: IUser | undefined;

  public get user(): IUser | undefined {
    if (!this._user) return;
    return { ...this._user, plas: this.userPLAS };
  }

  public get userPLAS(): IUserPLAS | undefined {
    return localStorageService.get(this._keyUserPLAS);
  }

  public async getDynamicResolverValue(
    resolverName: string,
    parameters?: string,
  ): Promise<IDynamicResolver> {
    return Promise.resolve({
      resolve: (attributeValue: string, parameters: string) =>
        HttpController.get(
          `permissions/dynamicResolverValue/${resolverName}${
            parameters ? '/' + parameters : ''
          }`,
        ),
    });
  }

  isExpired() {
    const expiryStr = localStorageService.get(this._keyExpiry);

    if (!expiryStr) {
      return true;
    }

    const expiryValue = JSON.parse(expiryStr);
    const now = new Date();
    // compare the expiryValue with the current time
    if (now.getTime() > expiryValue) {
      // If the item is expired, delete the item from storage
      // and return true
      localStorageService.remove(this._keyExpiry);
      return true;
    }
    return false;
  }

  public hydratePermissionIncludes(args: IHydrateArgs) {
    const body = { ...args } as any;
    delete body.permissionManager;
    return HttpController.post<IHydrateResult>(`permissions/hydrate`, body);
  }

  public async init(): Promise<void> {
    const accessToken = permissionManager.userPLAS?.access_token;
    if (!accessToken) return;

    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

    let rules: any = localStorageService.get(this._keyRules);
    let user: IUser = localStorageService.get(this._keyUser);
    let subjectNames: {
      [index: string]: string;
    } = localStorageService.get(this._keySubjectNames);

    if (!rules || this.isExpired()) {
      rules = await HttpController.get(
        `${config.plac.url}casl-rules/mine/name:${config.plac.application}`,
      );

      localStorageService.set(this._keyRules, rules);

      const now = new Date();
      // 1hr expiry time before we fetch the rules again
      localStorageService.set(this._keyExpiry, now.getTime() + 3600000);
    }

    if (!user) {
      user = await HttpController.get(`/users/current`);
      localStorageService.set(this._keyUser, user);
    }
    if (!subjectNames) {
      subjectNames = await HttpController.get(
        `/permissions/subjectToEntityMap`,
      );

      localStorageService.set(this._keySubjectNames, subjectNames);
    }

    this._user = user;
    this._permissionManager = new PermissionManagerPL(
      () => Promise.resolve(rules),
      this._permissionTTL,
      this._dbEngineName,
      () => Promise.resolve(subjectNames),
      {
        get: this.getDynamicResolverValue,
      },
      {
        hydrate: this.hydratePermissionIncludes,
      },
      config.plac.application,
    );
    await this._permissionManager.init();
  }

  public clearCache() {
    localStorageService.remove(this._keyUser);
    localStorageService.remove(this._keyRules);
  }

  async hasPermission(
    subject: string, //that should be the name of the subject stated in subjectNameToPrismaModel.ts -> ex: "Calendar.TrainingTask" or "Core.Team"
    action: IPermissionAction,
  ): Promise<boolean> {
    if (!this._permissionManager) return false;
    return await this._permissionManager.hasPermission(
      `${this._subjectBase}.${subject}`,
      action,
    );
  }
}

export const permissionManager = new PermissionManager();
