class StorageService {
  private _storage: Storage;

  constructor(storage: Storage) {
    this._storage = storage;
  }

  public set(key: string, value: any) {
    if (!value) return this.remove(key);
    this._storage.setItem(key, JSON.stringify(value))
  }

  public get(key: string) {
    const result = this._storage.getItem(key)
    if (!result || result === 'undefined') return;
    return JSON.parse(result);
  }

  public remove(key: string) {
    this._storage.removeItem(key);
  }
}

export const sessionStorageService = new StorageService(sessionStorage);
export const localStorageService = new StorageService(localStorage);
