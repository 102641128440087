import env from '@beam-australia/react-env';

export const config = {
  dev: env('NODE_ENV'),
  hostUrl: env('PUBLIC_URL'),
  apiUrl: env('API_URL'),
  plac: {
    url: env('PLAC_URL'),
    application: env('PLAC_APPLICATION'),
  },
  oidc: {
    authority: env('PLAS_URL'),
    clientId: env('PLAS_CLIENT_ID'),
    redirectUri: env('PUBLIC_URL'),
    scope: env('SCOPE'),
  },
};
