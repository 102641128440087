import React from "react";
import { Tag } from "antd";
import { TermStatus } from "../enums";
import { capitalizeFirstLetter } from "../utils";

const StateTag = ({ state }: { state: TermStatus }) => {
  const getColor = () => {
    switch (state) {
      case TermStatus.draft:
        return "blue";
      case TermStatus.pending:
        return "orange";
      case TermStatus.rejected:
        return "red";
      case TermStatus.approved:
        return "green";
      case TermStatus.submitted:
        return "green";
      case TermStatus.historical:
        return "gray";
      case TermStatus.provisionally_determined:
        return "purple";
    }
  };

  const stateEnum = Object.fromEntries(
    Object.keys(TermStatus).map((status) => [
      [status],
      {
        color: getColor(),
        display: capitalizeFirstLetter(status.replace("_", " ")),
      },
    ]),
  );

  const stateResult = stateEnum[state] || {
    color: "blue",
    display: "n/a",
  };

  return <Tag color={stateResult.color}>{stateResult.display}</Tag>;
};

export default StateTag;
