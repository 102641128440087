import React from "react";
import { createRoot } from "react-dom/client";
import axios from "axios";
import "./index.css";
import App from "./App";
import env from "@beam-australia/react-env";

axios.defaults.baseURL = env("API_URL");

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
