import React, { createContext, useContext, useEffect, useState } from "react";
import Countdown from "react-countdown";

import { Button, message, Modal, Typography } from "antd";
import { useAuth } from "oidc-react";
import { useNavigate } from "react-router-dom";

const { Paragraph } = Typography;

const countdownRender = ({ minutes, seconds }: any) =>
  minutes > 0 ? (
    <span>
      {minutes} minutes, {seconds} seconds
    </span>
  ) : (
    <span>{seconds} seconds</span>
  );

export const JwTExpiryModal = () => {
  const { isModalOpen, setIsModalOpen } = useJwTExpiryModal();

  const [expiresDate, setExpiresDate] = useState<Date>();
  const [isExtendLoading, setIsExtendLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const auth = useAuth();

  const refreshAccessToken = async () => {
    setIsExtendLoading(true);
    try {
      await auth.userManager.signinSilent();

      message.success("Access extended", 3.0);
      setIsExtendLoading(false);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error refreshing access token:", error);
    }
  };

  const logoutUserClick = async () => {
    await auth.signOut();
    setIsModalOpen(false);

    navigate(`/logout`);
  };

  const checkTokenExpiry = () => {
    const tokenExpirationTime = auth.userData.expires_at; // already in seconds
    const currentTime = Math.floor(new Date().getTime() / 1000); // Convert to seconds
    const secondsUntilExpire = tokenExpirationTime - currentTime; // in seconds

    // console.log("secondsUntilExpire --->", secondsUntilExpire);

    const expirationDate = new Date(
      new Date().getTime() + secondsUntilExpire * 1000,
    );
    // console.log("expiration date", expirationDate);

    setExpiresDate(expirationDate);

    if (secondsUntilExpire <= 0) {
      //token already expired, log user out
      return logoutUserClick();
    }

    if (!isModalOpen && secondsUntilExpire <= 300) {
      //due to expire in the next 5 mins
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    if (auth?.userData?.access_token) {
      //every 5 seconds, check if expiry due
      const interval = setInterval(() => {
        checkTokenExpiry();
      }, 5000);

      // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
      return () => clearInterval(interval);
    }
  }, [auth?.userData?.access_token]);

  return (
    <Modal
      title={`Session Due to Expire`}
      width={500}
      open={isModalOpen}
      closable={false}
      footer={[
        <Button key="back" onClick={() => logoutUserClick()}>
          Logout Now
        </Button>,

        <Button
          loading={isExtendLoading}
          key="submit"
          type="primary"
          onClick={() => refreshAccessToken()}
        >
          Extend Access
        </Button>,
      ]}
    >
      <Paragraph>
        Due to security purposes, your session is due to expire in: <br />
        <strong>
          <Countdown renderer={countdownRender} date={expiresDate} />.
        </strong>
        <br />
        <br />
        After this time you will be <b>logged out</b> of the application.
        <br />
        <br />
        Please click the Extend Access button below if you would like to
        continue using the Commercial Databank.
      </Paragraph>
    </Modal>
  );
};

const JwTExpiryModalContext = createContext({
  isModalOpen: false,
  setIsModalOpen: (value: boolean) => {},
});

const useJwTExpiryModal = () => useContext(JwTExpiryModalContext);

export const JwTExpiryModalProvider = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <JwTExpiryModalContext.Provider value={{ isModalOpen, setIsModalOpen }}>
      <JwTExpiryModal />
    </JwTExpiryModalContext.Provider>
  );
};
