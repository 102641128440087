import React from "react";
import { PageHeader } from "@ant-design/pro-components";

import styled from "styled-components";
import { VelocityOverview } from "./graphs/VelocityOverview";
import { TransactionsPerClub } from "./graphs/TransactionsPerClub";

const routes = {
  items: [{ title: "Dashboard", path: "dashboard" }],
};

const SPageHeader = styled(PageHeader)`
  border-bottom: 1px solid rgb(235, 237, 240);
  background: #fff;
`;

const SPageContent = styled.div`
  margin: 24px 24px 0;
  padding: inherit;
`;

const Overview = () => {
  return (
    <>
      <SPageHeader title={"Dashboard"} breadcrumb={routes} />
      <SPageContent>
        <VelocityOverview />
        <TransactionsPerClub />
      </SPageContent>
    </>
  );
};

export default Overview;
