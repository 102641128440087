import { useEffect, useMemo, useRef, useState } from 'react';
import { AttachmentFile } from '../types';
import { message } from 'antd';
import AttachmentService from '../services/attachment.service';

export const useGetAttachments = (termId: string) => {
  const [attachments, setAttachments] = useState<AttachmentFile[]>([]);
  const [attachmentsCount, setAttachmentsCount] = useState<number>();
  const [isFetchingAttachments, setIsFetchingAttachments] =
    useState<boolean>(false);
  const queryParamsRef = useRef<string>(termId);

  const attachmentService = useMemo(() => new AttachmentService(), []);
  const fetchAttachments = async () => {
    const hide = message.loading('Loading...');
    setIsFetchingAttachments(true);
    const attachments = await attachmentService.getAttachmentsForTerm(
      queryParamsRef.current
    );

    const attachmentsCompaniesHydrated = attachments.data.map(
      (attachment: AttachmentFile) => ({
        ...attachment,
      })
    );

    setAttachments(attachmentsCompaniesHydrated);
    setAttachmentsCount(attachments.count);
    setIsFetchingAttachments(false);
    hide();
  };

  useEffect(() => {
    queryParamsRef.current = termId;
    fetchAttachments();
  }, [termId]);

  const refetchAttachments = () => {
    fetchAttachments();
  };

  return {
    attachments,
    refetchAttachments,
    attachmentsCount,
    isFetchingAttachments,
  };
};
