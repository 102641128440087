import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import { LogoutOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Spin } from "antd";

import styled from "styled-components";
import { Auth } from "../types";
// import store from "../utils/store";
import { logoutUser } from "../actions";
import { useAuth } from "oidc-react";
import { useGetCurrentUser } from "../hooks/useGetCurrentUser";
import { logoResolver } from "../utils";

const SDropdown = styled(Dropdown)`
  > * {
    background-color: @popover-bg;
    border-radius: 4px;
    box-shadow: @shadow-1-down;
  }
`;

const SAvatarWrap = styled.span`
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
`;

const SAvatar = styled(Avatar)`
  margin-right: 8px;
`;

const AvatarDropdown = () => {
  const { user } = useGetCurrentUser();

  const [email, setEmail] = useState("");
  const [companyLogo, setCompanyLogo] = useState("pl.png");
  const navigate = useNavigate();

  useEffect(() => {
    if (user.email) {
      setEmail(user.email);
    }
    if (user.company && user.company.logo) {
      const resolvedLogoPath = logoResolver(user.company.logo);

      setCompanyLogo(resolvedLogoPath);
    }
  }, [user]);

  const onMenuClick = async (event: { key: string; keyPath: string[] }) => {
    const { key } = event;
    if (key === "logout") {
      console.log("logging out");
      // @ts-ignore
      // await store.dispatch(logoutUser());
      navigate(`/logout`);
    }
  };

  const loading = (
    <span>
      <Spin
        size="small"
        style={{
          marginLeft: 8,
          marginRight: 8,
        }}
      />
    </span>
  );

  if (!email) {
    return loading;
  }

  return (
    <SDropdown
      menu={{
        selectedKeys: [],
        style: {},
        onClick: onMenuClick,
        items: [
          {
            key: "logout",
            icon: <LogoutOutlined />,
            label: "Logout",
          },
        ],
      }}
    >
      <SAvatarWrap>
        <SAvatar size="small" src={`${companyLogo}`} alt="avatar" />
        <span className={` anticon`}>{email}</span>
      </SAvatarWrap>
    </SDropdown>
  );
};

export default AvatarDropdown;
